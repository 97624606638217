import { createSlice, current } from '@reduxjs/toolkit'
import { REDUX_STATE } from '@src/constants'
import { fileActions } from '@store/actions/file'
import { FILE_FIELDS } from '@constants/db'
import _ from 'lodash'

const { FIELDS } = REDUX_STATE.FILE

const authSlice = createSlice({
  name: REDUX_STATE.FILE.NAME,
  initialState: {
    [FIELDS.FILES]: [],
    [FIELDS.IS_GETTING]: false,
    [FIELDS.IS_UPLOADING]: false,
    [FIELDS.IS_DELETING]: false,
    [FIELDS.IS_UPDATING]: false,
    [FIELDS.ERROR]: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fileActions.upload.pending, (state) => {
        state[FIELDS.ERROR] = null
        state[FIELDS.IS_UPLOADING] = true
      })
      .addCase(fileActions.upload.fulfilled, (state, action) => {
        const currentFiles = current(state[FIELDS.FILES])
        const newFiles = action.payload

        state[FIELDS.FILES] = [...newFiles, ...currentFiles]
        state[FIELDS.IS_UPLOADING] = false
      })
      .addCase(fileActions.upload.rejected, (state, action) => {
        state[FIELDS.IS_UPLOADING] = false
        state[FIELDS.ERROR] = action.error
      })
      .addCase(fileActions.getFiles.pending, (state) => {
        state[FIELDS.FILES] = []
        state[FIELDS.ERROR] = null
        state[FIELDS.IS_GETTING] = true
      })
      .addCase(fileActions.getFiles.fulfilled, (state, action) => {
        state[FIELDS.FILES] = action.payload
        state[FIELDS.IS_GETTING] = false
      })
      .addCase(fileActions.getFiles.rejected, (state, action) => {
        state[FIELDS.IS_GETTING] = false
        state[FIELDS.ERROR] = action.error
      })
      .addCase(fileActions.loadMoreFiles.pending, (state) => {
        state[FIELDS.ERROR] = null
        state[FIELDS.IS_GETTING] = true
      })
      .addCase(fileActions.loadMoreFiles.fulfilled, (state, action) => {
        state[FIELDS.FILES] = [...state[FIELDS.FILES], ...action.payload]
        state[FIELDS.IS_GETTING] = false
      })
      .addCase(fileActions.loadMoreFiles.rejected, (state, action) => {
        state[FIELDS.IS_GETTING] = false
        state[FIELDS.ERROR] = action.error
      })
      .addCase(fileActions.updateFile.pending, (state) => {
        state[FIELDS.ERROR] = null
        state[FIELDS.IS_UPDATING] = true
      })
      .addCase(fileActions.updateFile.fulfilled, (state, action) => {
        const id = action.payload?.[FILE_FIELDS.ID.NAME]
        const index = _.findIndex(state[FIELDS.FILES], { [FILE_FIELDS.ID.NAME]: id })
        if (index !== -1) {
          state[FIELDS.FILES] = [
            ...state[FIELDS.FILES].slice(0, index),
            action.payload,
            ...state[FIELDS.FILES].slice(index + 1)
          ]
        }
        state[FIELDS.IS_UPDATING] = false
      })
      .addCase(fileActions.updateFile.rejected, (state, action) => {
        state[FIELDS.IS_UPDATING] = false
        state[FIELDS.ERROR] = action.error
      })
      .addCase(fileActions.deleteFile.pending, (state) => {
        state[FIELDS.ERROR] = null
        state[FIELDS.IS_DELETING] = true
      })
      .addCase(fileActions.deleteFile.fulfilled, (state, action) => {
        const id = action.payload

        state[FIELDS.FILES] = state[FIELDS.FILES]?.filter(
          (each) => each[FILE_FIELDS.ID.NAME] !== id
        )
        state[FIELDS.IS_DELETING] = false
      })
      .addCase(fileActions.deleteFile.rejected, (state, action) => {
        state[FIELDS.IS_DELETING] = false
        state[FIELDS.ERROR] = action.error
      })
  }
})

export default authSlice.reducer
