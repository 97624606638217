import React from 'react'
import xss from 'xss'
import PropTypes from 'prop-types'

const StringToHTMLWrapper = ({ htmlString, style }) => {
  return <div style={{ ...style }} dangerouslySetInnerHTML={{ __html: xss(htmlString) }} />
}

StringToHTMLWrapper.propTypes = {
  htmlString: PropTypes.string,
  style: PropTypes.object
}

export default StringToHTMLWrapper
