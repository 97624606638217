const REDUX_STATE = {
  AUTH: {
    NAME: 'auth',
    FIELDS: {
      ERROR: 'error',
      IS_LOGGED_IN: 'isLoggedIn',
      IS_LOGGING_IN: 'isLoggingIn',
      IS_LOGGING_OUT: 'isLoggingOut',
      IS_REGISTERING: 'isRegistering',
      IS_UPDATING: 'isUpdating',
      IS_VERIFYING: 'isVerifying',
      IS_UPDATING_PASSWORD: 'isUpdatingPassword',
      IS_FORCING_LOGOUT: 'isForcingLogout',
      IS_SUBMITTING_EMAIL: 'isSubmittingEmail',
      IS_SUBMITTED_EMAIL: 'isSubmittedEmail',
      IS_RESETTING_PASSWORD: 'isResettingPassword',
      IS_DELETING: 'isDeleting',
      IS_RESET_PASSWORD: 'isResetPassword',
      USER: 'user',
      SESSIONS: 'sessions'
    }
  },
  FEEDBACK_FORM: {
    NAME: 'feedbackForm',
    FIELDS: {
      ERROR: 'error',
      FEEDBACK_FORM: 'feedbackForm',
      IS_GETTING: 'isGetting'
    }
  },
  FILE: {
    NAME: 'file',
    FIELDS: {
      ERROR: 'error',
      IS_GETTING: 'isGetting',
      IS_UPLOADING: 'isUploading',
      IS_UPDATING: 'isUpdating',
      IS_DELETING: 'isDeleting',
      FILES: 'files'
    }
  },
  TOKEN: {
    NAME: 'token',
    FIELDS: {
      ERROR: 'error',
      IS_VERIFYING: 'isVerifying',
      IS_VERIFIED: 'isVerified'
    }
  },
  QUESTION_SET: {
    NAME: 'questionSet',
    FIELDS: {
      ERROR: 'error',
      QUESTION_SET: 'questionSet',
      SESSION: 'session',
      IS_GETTING_QUESTION: 'isGettingQuestion',
      IS_GETTING_QUESTION_SET: 'isGettingQuestionSet'
    }
  },
  GENERAL: {
    NAME: 'general',
    FIELDS: {
      ERROR: 'error',
      IS_GETTING: 'isGetting',
      GIT_VERSION: 'gitVersion'
    }
  },
  USER: {
    NAME: 'user',
    FIELDS: {
      ERROR: 'error',
      IS_GETTING_USERS: 'isGettingUsers',
      IS_UPDATING: 'isUpdating',
      IS_DELETING: 'isDeleting',
      USERS: 'users',
      TOTAL: 'total',
      IS_UNLOCKING_QUESTIONNAIRE: 'isUnlockingQuestionnaire'
    }
  },
  RECORD: {
    NAME: 'record',
    FIELDS: {
      ERROR: 'error',
      RECORDS: 'records',
      RECORD: 'record',
      SESSION: 'session',
      IS_INITIALIZING_RECORD: 'isInitializingRecord',
      IS_GETTING_RECORD: 'isGettingRecord',
      IS_UPDATING_ANSWER: 'isUpdatingAnswer',
      AVAILABLE_SESSION_ID: 'availableSessionId'
    }
  }
}

export { REDUX_STATE }
