import { createAsyncThunk } from '@reduxjs/toolkit'
import { USER_ACTION_TYPES } from '@store/types/user'
import { userApi } from '@src/apis'
import { serializeReduxError } from '@src/utils'

const getUsers = createAsyncThunk(
  USER_ACTION_TYPES.GET_USERS,
  async (query) => {
    return await userApi.getUsers(query)
  },
  {
    serializeError: serializeReduxError
  }
)

/**
 * Update user
 */
const updateUser = createAsyncThunk(
  USER_ACTION_TYPES.UPDATE_USER,
  async ({ id, body }) => {
    return await userApi.updateUser({ id, body })
  },
  {
    serializeError: serializeReduxError
  }
)

/**
 * Delete user
 */
const deleteUser = createAsyncThunk(
  USER_ACTION_TYPES.DELETE_USER,
  async (id) => {
    return await userApi.deleteUser(id)
  },
  {
    serializeError: serializeReduxError
  }
)

/**
 * Unlock questionnaire for a user
 *
 */
const unlockQuestionnaire = createAsyncThunk(
  USER_ACTION_TYPES.UNLOCK_QUESTIONNAIRE,
  async (id) => {
    return await userApi.unlockQuestionnaire(id)
  },
  {
    serializeError: serializeReduxError
  }
)

const userActions = {
  getUsers,
  updateUser,
  deleteUser,
  unlockQuestionnaire
}

export { userActions }
