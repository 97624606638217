import React, { useEffect, useRef } from 'react'
import { Button, Result } from 'antd'
import { HoverCardWrapper } from '@src/styles/wrapper'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '@store/actions/auth'
import { REDUX_STATE, ROUTE_PATH } from '@src/constants'
import { useShowMessages } from '@src/utils/hooks'

const MobileOnlyPromptPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const error = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.ERROR]
  )
  const isLoggingOut = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.IS_LOGGING_OUT]
  )
  const prevIsLoggingOut = useRef(isLoggingOut)

  /**
   * Redirect after logout
   */
  useEffect(() => {
    if (!error && !isLoggingOut && prevIsLoggingOut.current) {
      navigate(ROUTE_PATH.ROOT.NAME)
    }
    prevIsLoggingOut.current = isLoggingOut
  }, [error, isLoggingOut, navigate])

  useShowMessages({ error })

  return (
    <HoverCardWrapper
      style={{
        height: 'max-content',
        minHeight: '100vh',
        overflowY: 'scroll',
        padding: '10px'
      }}
    >
      <Result
        style={{ margin: '0 auto' }}
        status='403'
        title={`Sorry, you can only use mobile devices to access this page.`}
        extra={[
          <Button
            type={'primary'}
            key={'back-btn'}
            onClick={() => {
              dispatch(authActions.logout())
            }}
          >
            Logout
          </Button>
        ]}
      />
    </HoverCardWrapper>
  )
}

export default MobileOnlyPromptPage
