import { USER_FIELDS } from '@constants/db'
import { isMobileDevices } from '@src/utils'
import { PERMISSIONS } from '@constants/permissions'

const ROUTE_PATH = {
  ROOT: {
    NAME: '/',
    PERMISSION_BY_ROLE: () => {
      return {
        [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: true,
        [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: true
      }
    }
  },
  MOBILE_ONLY_PROMPT_PAGE: {
    NAME: '/mobile-only',
    PERMISSION_BY_ROLE: (_, isEnable) => {
      return {
        [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: true,
        [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: isEnable ? isMobileDevices : true
      }
    }
  },
  NO_PERMISSION_PROMPT_PAGE: {
    NAME: '/no-permission',
    PERMISSION_BY_ROLE: () => {
      return {
        [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: true,
        [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: true
      }
    }
  },
  LOGIN: {
    NAME: '/login',
    PERMISSION_BY_ROLE: () => {
      return {
        [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: true,
        [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: true
      }
    }
  },
  REGISTER: {
    NAME: '/register',
    PERMISSION_BY_ROLE: () => {
      return {
        [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: true,
        [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: true
      }
    }
  },
  ADMIN: {
    NAME: '/admin',
    PERMISSION_BY_ROLE: (role) => {
      if (!role) {
        return false
      } else if (role === USER_FIELDS.ROLE.ENUMS.ADMIN) {
        return {
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: true,
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: true
        }
      } else {
        return {
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: false,
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: false
        }
      }
    }
  },
  TOKEN: {
    NAME: '/token',
    PERMISSION_BY_ROLE: (role) => {
      if (!role) {
        return false
      } else if (role === USER_FIELDS.ROLE.ENUMS.ADMIN) {
        return {
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: true,
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: true
        }
      } else {
        return {
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: true,
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: true
        }
      }
    }
  },
  PROFILE: {
    NAME: '/profile',
    PERMISSION_BY_ROLE: (role, isEnable) => {
      if (!role) {
        return false
      } else if (role === USER_FIELDS.ROLE.ENUMS.ADMIN) {
        return {
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: true,
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: true
        }
      } else {
        return {
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: true,
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: isEnable ? isMobileDevices : true
        }
      }
    }
  },
  SESSIONS: {
    NAME: '/sessions',
    PERMISSION_BY_ROLE: (role, isEnable) => {
      if (!role) {
        return false
      } else if (role === USER_FIELDS.ROLE.ENUMS.ADMIN) {
        return {
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: false,
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: true
        }
      } else {
        return {
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: true,
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: isEnable ? isMobileDevices : true
        }
      }
    }
  },
  SESSION: {
    NAME: '/sessions/:id',
    PERMISSION_BY_ROLE: (role, isEnable) => {
      if (!role) {
        return false
      } else if (role === USER_FIELDS.ROLE.ENUMS.ADMIN) {
        return {
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: false,
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: true
        }
      } else {
        return {
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: true,
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: isEnable ? isMobileDevices : true
        }
      }
    }
  },
  RESET_PASSWORD_REQUEST: {
    NAME: '/reset-password',
    PERMISSION_BY_ROLE: () => {
      return {
        [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: true,
        [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: true
      }
    }
  },
  RESET_PASSWORD: {
    NAME: '/reset-password/:token',
    PERMISSION_BY_ROLE: () => {
      return {
        [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: true,
        [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: true
      }
    }
  },
  PLAIN_LANGUAGE_STATEMENT: {
    NAME: '/plain-language-statement',
    PERMISSION_BY_ROLE: (role, isEnable) => {
      if (!role) {
        return false
      } else if (role === USER_FIELDS.ROLE.ENUMS.ADMIN) {
        return {
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: true,
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: true
        }
      } else {
        return {
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: true,
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: isEnable ? isMobileDevices : true
        }
      }
    }
  },
  CONSENT_FORM: {
    NAME: '/consent-form',
    PERMISSION_BY_ROLE: (role, isEnable) => {
      if (!role) {
        return false
      } else if (role === USER_FIELDS.ROLE.ENUMS.ADMIN) {
        return {
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: true,
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: true
        }
      } else {
        return {
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: true,
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: isEnable ? isMobileDevices : true
        }
      }
    }
  },
  QUESTIONNAIRE_PAGE: {
    NAME: '/questionnaires/:id',
    PERMISSION_BY_ROLE: (role, isEnable) => {
      if (!role) {
        return false
      } else if (role === USER_FIELDS.ROLE.ENUMS.ADMIN) {
        return {
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: true,
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: true
        }
      } else {
        return {
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]: true,
          [PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]: isEnable ? isMobileDevices : true
        }
      }
    }
  }
}

export { ROUTE_PATH }
