const COMMON_FIELDS = {
  ID: {
    NAME: '_id'
  },
  CREATED_AT: {
    NAME: 'createdAt'
  },
  UPDATED_AT: {
    NAME: 'updatedAt'
  }
}

export { COMMON_FIELDS }
