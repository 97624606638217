import React, { useState } from 'react'
import { FloatButton } from 'antd'
import PropTypes from 'prop-types'
import ChatRobot from '@components/chat-robot'

const HelperButtons = ({ isShowChatBtn = true }) => {
  const [isOpenChat, setIsOpenChat] = useState(false)

  return (
    <FloatButton.Group>
      {isShowChatBtn && (
        <ChatRobot
          onOpen={() => setIsOpenChat(true)}
          onClose={() => setIsOpenChat(false)}
          isOpen={isOpenChat}
        />
      )}
      <FloatButton.BackTop visibilityHeight={50} />
    </FloatButton.Group>
  )
}

HelperButtons.propTypes = {
  isShowChatBtn: PropTypes.bool
}

export default HelperButtons
