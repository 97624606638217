import { COMMON_FIELDS } from './common'

const RECORD_FIELDS = {
  ...COMMON_FIELDS,
  STATUS: {
    NAME: 'status',
    ENUMS: {
      OPEN: 'open',
      COMPLETED: 'completed',
      UNLOCKED: 'unlocked',
      UNLOCK_COMPLETED: 'unlockCompleted'
    }
  },
  CONFIG: {
    MAX_SESSION_PER_DAY: {
      NAME: 'maxSessionPerDay'
    }
  },
  PROGRESS: {
    NAME: 'progress',
    FIELDS: {
      ORDER: { NAME: 'order' },
      LATEST_SESSION_GROUP: {
        NAME: 'latestSessionGroup'
      },
      COMPLETED_SESSION_TODAY: {
        NAME: 'completedSessionToday'
      },
      REFRESH_AFTER: {
        NAME: 'refreshAfter'
      },
      COMBINATIONS: {
        NAME: 'combinations',
        FIELDS: {
          ID: { NAME: COMMON_FIELDS.ID.NAME },
          IMAGE: { NAME: 'image' },
          DOT_IMAGE: { NAME: 'dotImage' },
          DOTS: { NAME: 'dots' },
          EMOTION: {
            NAME: 'emotion',
            ENUMS: {
              ANGRY: 'angry',
              NEUTRAL: 'neutral'
            }
          },
          POSITION: { NAME: 'position' }
        }
      }
    }
  },
  RESPONSES: {
    NAME: 'responses',
    FIELDS: {
      CREATED_AT: { NAME: COMMON_FIELDS.CREATED_AT.NAME },
      UPDATED_AT: { NAME: COMMON_FIELDS.UPDATED_AT.NAME },
      QUESTION_ID: { NAME: 'questionId' },
      TYPE: {
        NAME: 'type',
        ENUMS: {
          SESSION: 'session',
          FEEDBACK: 'feedback'
        }
      },
      COUNT: { NAME: 'count' },
      STATUS: {
        NAME: 'status',
        ENUMS: {
          OPEN: 'open',
          IN_PROGRESS: 'in_progress',
          COMPLETED: 'completed'
        }
      },
      VALID_UNTIL: {
        NAME: 'validUntil'
      },
      VALUES: {
        NAME: 'values'
      }
    }
  },
  OWNER: {
    NAME: 'owner'
  },
  QUESTION_SET_ID: {
    NAME: 'questionSetId'
  },
  UNLOCKED_QUESTIONS: {
    NAME: 'unlockedQuestions'
  }
}

export { RECORD_FIELDS }
