import { REDUX_STATE } from '@src/constants'
import { createSlice } from '@reduxjs/toolkit'
import { feedbackFormActions } from '@store/actions/feedback-form'

const { FIELDS, NAME } = REDUX_STATE.FEEDBACK_FORM

const feedbackFormSlice = createSlice({
  name: NAME,
  initialState: {
    [FIELDS.IS_GETTING]: false,
    [FIELDS.FEEDBACK_FORM]: null,
    [FIELDS.ERROR]: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(feedbackFormActions.getFeedbackForms.pending, (state) => {
        state[FIELDS.ERROR] = null
        state[FIELDS.IS_GETTING] = true
      })
      .addCase(feedbackFormActions.getFeedbackForms.fulfilled, (state, action) => {
        state[FIELDS.IS_GETTING] = false
        state[FIELDS.FEEDBACK_FORM] = action.payload
      })
      .addCase(feedbackFormActions.getFeedbackForms.rejected, (state, action) => {
        state[FIELDS.IS_GETTING] = false
        state[FIELDS.ERROR] = action.error
      })
  }
})

export default feedbackFormSlice.reducer
