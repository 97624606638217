import { BaseApi } from '@src/apis/base'

class FeedbackFormApi extends BaseApi {
  /**
   * Get feedback forms
   * @return {Promise<*>}
   */
  async getFeedbackForms() {
    try {
      const res = await this.client.get(this.endpoints.FEEDBACK_FORM.FEEDBACK_FORMS)
      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }
}

export { FeedbackFormApi }
