import React from 'react'
import { List, Tag } from 'antd'
import { USER_FIELDS } from '@constants/db'
import { HoverCardWrapper } from '@src/styles/wrapper'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { getCSSVariable } from '@src/utils'
import { CSS_VARIABLES, SESSION_STATUS_COLORS } from '@src/constants'
import PropTypes from 'prop-types'

const UserList = ({ users }) => {
  return (
    <List
      style={{ overflow: 'hidden' }}
      dataSource={users}
      renderItem={(item) => {
        return (
          <List.Item key={item?.[USER_FIELDS.ID.NAME]}>
            <HoverCardWrapper style={{ width: '100%', paddingTop: '5px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  textAlign: 'left'
                }}
              >
                {/*name*/}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '5px',
                    fontWeight: '600',
                    fontSize: `${getCSSVariable(CSS_VARIABLES.FONT_SIZE_HEADER_4)}px`
                  }}
                >
                  {item?.[USER_FIELDS.FIRST_NAME.NAME]} {item?.[USER_FIELDS.LAST_NAME.NAME]}
                </div>
                <div>
                  ({item?.[USER_FIELDS.EMAIL.NAME]}{' '}
                  {item?.[USER_FIELDS.IS_VERIFIED.NAME] ? (
                    <CheckOutlined styled={{ color: 'green' }} />
                  ) : (
                    <CloseOutlined style={{ color: 'red' }} />
                  )}
                  )
                </div>
                {/*gender*/}
                <div>
                  <div style={{ color: 'lightgrey', fontSize: '12px' }}>Gender</div>
                  <div>Male</div>
                </div>
                {/*marry*/}
                <div>
                  <div style={{ color: 'lightgrey', fontSize: '12px' }}>Marry status</div>
                  <div>Married / defacto</div>
                </div>
                <div>
                  <div style={{ color: 'lightgrey', fontSize: '12px' }}>Session status</div>
                  <div>
                    {item?.sessionStatus && (
                      <Tag color={SESSION_STATUS_COLORS[item?.sessionStatus]}>
                        {item?.sessionStatus}
                      </Tag>
                    )}{' '}
                    {!item?.sessionStatus && <Tag color={'red'}>Not started</Tag>}
                    {item?.sessionCompleted} / 10
                  </div>
                </div>
              </div>
            </HoverCardWrapper>
          </List.Item>
        )
      }}
    />
  )
}

UserList.propTypes = {
  users: PropTypes.array
}

export default UserList
