import React, { useEffect, useRef } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { LayoutWithBackground } from '@src/styles/wrapper'
import { usePreventZoomingIn } from '@src/utils/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { REDUX_STATE, ROUTE_PATH } from '@src/constants'
import { USER_FIELDS } from '@constants/db'
import HelperButtons from '@components/helper-buttons'

const PublicRoute = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const path = location?.pathname
  const error = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.ERROR]
  )
  const isVerifying = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.IS_VERIFYING]
  )
  const prevIsVerifying = useRef(isVerifying)
  const user = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.USER]
  )
  const role = user?.[USER_FIELDS.ROLE.NAME]

  usePreventZoomingIn()

  /**
   * Redirect to dashboard page if user already logged in
   */
  useEffect(() => {
    if (
      ![
        ROUTE_PATH.NO_PERMISSION_PROMPT_PAGE.NAME,
        ROUTE_PATH.MOBILE_ONLY_PROMPT_PAGE.NAME
      ].includes(path) &&
      !error &&
      prevIsVerifying.current &&
      !isVerifying
    ) {
      navigate(
        role === USER_FIELDS.ROLE.ENUMS.ADMIN ? ROUTE_PATH.ADMIN.NAME : ROUTE_PATH.SESSIONS.NAME
      )
    }
    prevIsVerifying.current = isVerifying
  }, [error, isVerifying, navigate, dispatch, role, path])

  return (
    <LayoutWithBackground>
      <HelperButtons />
      <LayoutWithBackground.Content style={{ overflowY: 'scroll', minHeight: '100vh' }}>
        <Outlet />
      </LayoutWithBackground.Content>
    </LayoutWithBackground>
  )
}

export default PublicRoute
