import React from 'react'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

const UserMessage = ({ message }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
        padding: '15px',
        alignItems: 'center',
        textAlign: 'right'
      }}
    >
      <div
        style={{
          border: '1px solid lightgrey',
          padding: '5px',
          width: '100%',
          borderRadius: '10px',
          backgroundColor: 'azure'
        }}
      >
        <div style={{ fontWeight: '700', color: 'lightgrey' }}>{message?.time}</div>
        <div
          style={{
            overflowX: 'hidden'
          }}
        >
          {message?.content}
        </div>
      </div>
      <Avatar
        shape='square'
        draggable={false}
        size={40}
        icon={<UserOutlined />}
        style={{
          backgroundColor: '#fde3cf',
          color: '#f56a00'
        }}
      />
    </div>
  )
}

UserMessage.propTypes = {
  message: PropTypes.shape({
    time: PropTypes.string,
    content: PropTypes.object
  })
}

export default UserMessage
