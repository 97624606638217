import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { REDUX_STATE } from '@src/constants'
import authReducer from '@store/reducers/auth'
import feedbackFormReducer from '@store/reducers/feedback-form'
import fileReducer from '@store/reducers/file'
import tokenReducer from '@store/reducers/token'
import questionSetReducer from '@store/reducers/question-set'
import generalReducer from '@store/reducers/general'
import userReducer from '@store/reducers/user'
import recordReducer from '@store/reducers/record'

const rootReducer = combineReducers({
  [REDUX_STATE.AUTH.NAME]: authReducer,
  [REDUX_STATE.FEEDBACK_FORM.NAME]: feedbackFormReducer,
  [REDUX_STATE.FILE.NAME]: fileReducer,
  [REDUX_STATE.TOKEN.NAME]: tokenReducer,
  [REDUX_STATE.QUESTION_SET.NAME]: questionSetReducer,
  [REDUX_STATE.GENERAL.NAME]: generalReducer,
  [REDUX_STATE.USER.NAME]: userReducer,
  [REDUX_STATE.RECORD.NAME]: recordReducer
})

const store = configureStore({
  reducer: rootReducer
})

export default store
