import React from 'react'
import { PASSWORD_STRENGTH } from '@src/constants'
import PropTypes from 'prop-types'
import { Typography } from 'antd'

const PassWordStrengthIndicator = ({ strength }) => {
  /**
   * Generate different color for different password strength
   * @param strength
   * @returns {string}
   */
  const getProgressColor = (strength) => {
    switch (strength) {
      case PASSWORD_STRENGTH.TOO_WEAK:
        return 'red'
      case PASSWORD_STRENGTH.WEAK:
        return 'orange'
      case PASSWORD_STRENGTH.MEDIUM:
        return 'yellow'
      case PASSWORD_STRENGTH.STRONG:
        return 'green'
      default:
        return 'transparent'
    }
  }

  /**
   * Change progress bar length
   * @param {String} strength
   * @returns {string}
   */
  const getProgressWidth = (strength) => {
    switch (strength) {
      case PASSWORD_STRENGTH.TOO_WEAK:
        return '25%'
      case PASSWORD_STRENGTH.WEAK:
        return '50%'
      case PASSWORD_STRENGTH.MEDIUM:
        return '75%'
      case PASSWORD_STRENGTH.STRONG:
        return '100%'
      default:
        return '0%'
    }
  }

  return (
    <div>
      <div
        style={{
          height: '10px',
          borderRadius: '5px'
        }}
      >
        <div
          style={{
            height: '100%',
            borderRadius: '5px',
            transition: 'width 3s ease',
            width: `${getProgressWidth(strength)}`,
            backgroundColor: getProgressColor(strength)
          }}
        />
      </div>
      <Typography.Paragraph style={{ color: getProgressColor(strength) }}>
        {strength}
      </Typography.Paragraph>
    </div>
  )
}

PassWordStrengthIndicator.propTypes = {
  strength: PropTypes.string
}
export default PassWordStrengthIndicator
