import React from 'react'
import { HoverCardWrapper } from '@src/styles/wrapper'
import { Button, Result } from 'antd'
import { ROUTE_PATH } from '@src/constants'
import { useNavigate } from 'react-router-dom'

const NoPermissionPromptPage = () => {
  const navigate = useNavigate()
  return (
    <HoverCardWrapper
      style={{
        height: 'max-content',
        minHeight: '100vh',
        overflowY: 'scroll',
        padding: '10px'
      }}
    >
      <Result
        style={{ margin: '0 auto' }}
        status='403'
        title={`Sorry, you don't have permission to access this page.`}
        extra={[
          <Button
            key={'back-btn'}
            onClick={() => {
              navigate(ROUTE_PATH.ROOT.NAME)
            }}
          >
            Back
          </Button>
        ]}
      />
    </HoverCardWrapper>
  )
}
export default NoPermissionPromptPage
