import { createAsyncThunk } from '@reduxjs/toolkit'
import { serializeReduxError } from '@src/utils'
import { GENERAL_ACTION_TYPES } from '@store/types'
import { generalApi } from '@src/apis'

const getGitVersion = createAsyncThunk(
  GENERAL_ACTION_TYPES.GET_GIT_VERSION,
  async () => {
    return await generalApi.getGitVersion()
  },
  {
    serializeError: serializeReduxError
  }
)

const generalActions = {
  getGitVersion
}
export { generalActions }
