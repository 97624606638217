import { useDispatch, useSelector } from 'react-redux'
import {
  DATE_TIME_FORMAT,
  LIMIT_PER_PAGE,
  MESSAGES,
  REDUX_STATE,
  SESSION_STATUS_COLORS
} from '@src/constants'
import React, { useEffect, useRef, useState } from 'react'
import { userActions } from '@store/actions/user'
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography
} from 'antd'
import {
  CheckOutlined,
  CloseOutlined,
  DeleteTwoTone,
  EditOutlined,
  LockTwoTone,
  UnlockTwoTone
} from '@ant-design/icons'
import { USER_FIELDS } from '@constants/db'
import { useShowMessages } from '@src/utils/hooks'
import PropTypes from 'prop-types'
import moment from 'moment'

const EditableCell = ({ editing, dataIndex, title, inputType, children, ...restProps }) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

const UserTable = ({ users }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const userCount = useSelector(
    (state) => state?.[REDUX_STATE.USER.NAME]?.[REDUX_STATE.USER.FIELDS.TOTAL]
  )
  const isUpdating = useSelector(
    (state) => state?.[REDUX_STATE.USER.NAME]?.[REDUX_STATE.USER.FIELDS.IS_UPDATING]
  )
  const isDeleting = useSelector(
    (state) => state?.[REDUX_STATE.USER.NAME]?.[REDUX_STATE.USER.FIELDS.IS_DELETING]
  )
  const isGetting = useSelector(
    (state) => state?.[REDUX_STATE.USER.NAME]?.[REDUX_STATE.USER.FIELDS.IS_GETTING_USERS]
  )
  const isUnlockingQuestionnaire = useSelector(
    (state) => state?.[REDUX_STATE.USER.NAME]?.[REDUX_STATE.USER.FIELDS.IS_UNLOCKING_QUESTIONNAIRE]
  )
  const error = useSelector(
    (state) => state?.[REDUX_STATE.USER.NAME]?.[REDUX_STATE.USER.FIELDS.ERROR]
  )
  const [editingKey, setEditingKey] = useState('')
  const prevIsUpdating = useRef(isUpdating)
  const prevIsDeleting = useRef(isDeleting)
  const prevIsUnlockingQuestionnaire = useRef(isUnlockingQuestionnaire)
  const isEditing = (record) => record === editingKey
  const [successMsg, setSuccessMsg] = useState({ message: null })
  const [currentPage, setCurrentPage] = useState(1)

  useShowMessages({ error, success: successMsg })

  /**
   * Show success message after updating successfully
   */
  useEffect(() => {
    if (!isUpdating && prevIsUpdating.current && !error) {
      setEditingKey('')
      setSuccessMsg({ message: MESSAGES.PROMPT.USER_UPDATED_SUCCESSFULLY })
    }
    prevIsUpdating.current = isUpdating
  }, [error, isUpdating])

  /**
   * Show success message after deletion successfully
   */
  useEffect(() => {
    if (!isDeleting && prevIsDeleting.current && !error) {
      setSuccessMsg({ message: MESSAGES.PROMPT.USER_DELETED_SUCCESSFULLY })
    }
    prevIsDeleting.current = isDeleting
  }, [error, isDeleting])

  /**
   * Show success message after question unlocked successfully
   */
  useEffect(() => {
    if (!isUnlockingQuestionnaire && prevIsUnlockingQuestionnaire.current && !error) {
      setSuccessMsg({ message: MESSAGES.PROMPT.QUESTION_UNLOCKED })
    }
    prevIsUnlockingQuestionnaire.current = isUnlockingQuestionnaire
  }, [error, isUnlockingQuestionnaire])

  const edit = (record) => {
    form.setFieldsValue({
      [USER_FIELDS.FIRST_NAME.NAME]: '',
      [USER_FIELDS.LAST_NAME.NAME]: '',
      isVerified: '',
      [USER_FIELDS.EMAIL.NAME]: '',
      sessionCompleted: '',
      ...record
    })
    setEditingKey(record)
  }
  const cancel = () => {
    setEditingKey('')
  }

  const save = async (record) => {
    const body = {
      ...editingKey,
      ...record
    }
    dispatch(userActions.updateUser({ id: body[USER_FIELDS.ID.NAME], body }))
  }

  const unlockQuestionnaire = (record) => {
    const userId = record[USER_FIELDS.ID.NAME]
    dispatch(userActions.unlockQuestionnaire({ id: userId }))
  }

  const columns = [
    {
      title: 'First Name',
      dataIndex: USER_FIELDS.FIRST_NAME.NAME,
      editable: true
    },
    {
      title: 'Last Name',
      dataIndex: USER_FIELDS.LAST_NAME.NAME,
      editable: true
    },
    {
      title: 'Email',
      dataIndex: [USER_FIELDS.EMAIL.NAME]
    },
    {
      title: 'Email verified',
      dataIndex: 'isVerified',
      render: (isVerified) => {
        return (
          <div style={{ textAlign: 'left' }}>
            {isVerified ? (
              <CheckOutlined styled={{ color: 'green' }} />
            ) : (
              <CloseOutlined style={{ color: 'red' }} />
            )}
          </div>
        )
      }
    },
    {
      title: 'Session Status',
      dataIndex: 'sessionStatus',
      render: (text) =>
        text ? (
          <Tag color={SESSION_STATUS_COLORS[text]}>{text}</Tag>
        ) : (
          <Tag color={'red'}>Not started</Tag>
        )
    },
    {
      title: 'Current Session',
      dataIndex: 'currentSession',
      render: (text) => `${text} / 10`
    },
    {
      title: 'Date of Last Session Accessed',
      dataIndex: 'lastSessionAccessedDate',
      render: (date) => (date ? moment(date).format(DATE_TIME_FORMAT) : '')
    },

    {
      title: 'Last Questionnaire Accessed',
      dataIndex: 'lastQuestionnaireAccessed'
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      fixed: 'right',
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (
          <Space align={'center'} split={<Divider type={'vertical'} />}>
            <Typography.Link
              onClick={() => form.submit()}
              style={{
                marginRight: 8
              }}
            >
              Save
            </Typography.Link>
            <Typography.Link onClick={cancel}>Cancel</Typography.Link>
          </Space>
        ) : (
          <Space align={'center'} split={<Divider type={'vertical'} />}>
            <Button
              icon={<EditOutlined />}
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
            />
            <Tooltip
              title={record?.isUnlockedQuestion ? 'Questionnaire Unlocked' : 'Unlock Questionnaire'}
            >
              <Button
                icon={record?.isUnlockedQuestion ? <UnlockTwoTone /> : <LockTwoTone />}
                disabled={record?.isUnlockedQuestion}
                onClick={() => unlockQuestionnaire(record)}
              />
            </Tooltip>
            <Popconfirm
              title={'Delete user?'}
              onConfirm={() => dispatch(userActions.deleteUser(record?.[USER_FIELDS.ID.NAME]))}
            >
              <Button icon={<DeleteTwoTone twoToneColor={'#eb2f96'} />} />
            </Popconfirm>
          </Space>
        )
      }
    }
  ]

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    }
  })

  const loadMoreUsers = (page) => {
    setCurrentPage(page)
    dispatch(
      userActions.getUsers({
        skip: LIMIT_PER_PAGE * (page - 1),
        limit: LIMIT_PER_PAGE
      })
    )
  }

  return (
    <>
      <Form form={form} component={false} onFinish={save}>
        <Table
          loading={isUpdating || isDeleting || isGetting}
          components={{
            body: {
              cell: EditableCell
            }
          }}
          columns={mergedColumns}
          rowKey={(record) => record?.[USER_FIELDS.ID.NAME]}
          dataSource={users}
          pagination={{
            total: userCount,
            current: currentPage,
            onChange: loadMoreUsers
          }}
        />
      </Form>
    </>
  )
}

EditableCell.propTypes = {
  editing: PropTypes.bool,
  dataIndex: PropTypes.string,
  title: PropTypes.string,
  inputType: PropTypes.string,
  children: PropTypes.array
}

UserTable.propTypes = {
  users: PropTypes.array
}

export default UserTable
