import { COMMON_FIELDS } from './common'

const PASSPORT_SESSION_FIELDS = {
  ID: {
    NAME: COMMON_FIELDS.ID.NAME
  },
  EXPIRES: {
    NAME: 'expires'
  },
  SESSION: {
    NAME: 'session',
    FIELDS: {
      COOKIE: {
        NAME: 'cookie'
      },
      PASSPORT: {
        NAME: 'passport',
        FIELDS: {
          USER: {
            NAME: 'user'
          }
        }
      },
      DEVICE: {
        NAME: 'device',
        FIELDS: {
          PLATFORM: { NAME: 'platform' },
          BROWSER: {
            NAME: 'browser'
          },
          IS_MOBILE: { NAME: 'isMobile' },
          IS_TABLET: { NAME: 'isTablet' },
          IS_DESKTOP: { NAME: 'isDesktop' }
        }
      },
      CREATED_AT: { NAME: 'createdAt' }
    }
  }
}

export { PASSPORT_SESSION_FIELDS }
