import ExpJSON from '@src/static/exp.json'

const isMobile = /Mobi|iPhone|iPod|Android/i.test(navigator.userAgent)
/**
 * Detect pad
 * @returns {boolean}
 */
const isTablet = () => {
  const userAgent = navigator.userAgent.toLowerCase()

  // iPad detection
  const isiPad =
    /ipad/.test(userAgent) ||
    (/Macintosh/i.test(navigator.userAgent) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 1)

  // Android tablet detection
  const isAndroidTablet = /android/.test(userAgent) && !/mobile/.test(userAgent)

  // Windows tablet detection
  const isWindowsTablet =
    /windows/.test(userAgent) && /touch/.test(userAgent) && !/tablet pc/.test(userAgent)

  // Amazon Fire tablet detection
  const isAmazonFireTablet = /silk/.test(userAgent) && /tablet/.test(userAgent)

  // General tablet detection
  return isiPad || isAndroidTablet || isWindowsTablet || isAmazonFireTablet
}

const isMobileDevices = isMobile || isTablet()

const isExtremeSmallDevices = window.innerHeight < 680

/**
 * Use to serialize redux error or the custom error field cannot be extracted
 * We use the url to prevent error in other page display in current page
 * @param err
 * @returns {{stack, name, message, status, url}}
 */
const serializeReduxError = (err) => {
  const hash = window.location.hash

  const pathname = hash ? hash.substring(1) : '' // Remove the leading '#'

  return {
    name: err.name,
    status: err.status,
    message: err.message,
    stack: err.stack,
    url: pathname
  }
}

/**
 * Get global css var value
 * @param name
 * @returns {string}
 */
const getCSSVariable = (name) => {
  return getComputedStyle(document.documentElement).getPropertyValue(name)
}

/**
 * Rotate a list of points based on a center and get result points
 * @param {Array} point - coordinator of a point going to rotate
 * @param {Number} rotateDegree
 * @param {Number} centerX
 * @param {Number} centerY
 * @returns {[*,*]}
 */
const getPointAfterRotate = ({ point, rotateDegree, centerX, centerY }) => {
  const radius = rotateDegree * (Math.PI / 180)
  const [x, y] = point
  const translatedX = x - centerX
  const translatedY = y - centerY
  const rotateX = translatedX * Math.cos(radius) - translatedY * Math.sin(radius)
  const rotateY = translatedX * Math.sin(radius) + translatedY * Math.cos(radius)
  const newX = rotateX + centerX
  const newY = rotateY + centerY

  return [newX, newY]
}

/**
 * Random pick N element from array
 * @param {Array} array
 * @param {Number} num
 * @returns {[]}
 */
const randomPickFromArray = ({ array, num }) => {
  if (num === 0) return []
  if (num === 1) {
    const index = Math.floor(Math.random() * array.length)
    return [array[index]]
  } else {
    const shuffled = [...array]

    for (let i = shuffled.length; i > 0; i--) {
      const randomIndex = Math.floor(Math.random() * (i + 1))
      ;[shuffled[i], shuffled[randomIndex]] = [shuffled[randomIndex], shuffled[i]]
    }
    return shuffled.slice(0, num)
  }
}

/**
 * Get dominant bg color from images
 * @param {Array} images - [{src: }, {src: }]
 * @returns {Promise<string>}
 */
const extractBackgroundColorFromImages = async (images) => {
  let sumR = 0,
    sumG = 0,
    sumB = 0,
    sumA = 0
  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve(img)
      img.onerror = (err) => reject(err)
      img.src = url
    })
  }

  try {
    for (const image of images) {
      const img = await loadImage(image?.url)
      const canvas = document.createElement('canvas')
      canvas.style.display = 'none'
      const context = canvas.getContext('2d')

      // Set canvas dimensions to match the image
      canvas.width = img.width
      canvas.height = img.height

      // Draw the image on the canvas
      context.drawImage(img, 0, 0)

      // Get the pixel data at the top-left corner (0, 0)
      const pixelData = context.getImageData(0, 0, 1, 1).data

      // Extract RGB values
      const [r, g, b, a] = pixelData

      sumR += r
      sumG += g
      sumB += b
      sumA += a
    }

    // Calculate average RGBA values
    const avgR = Math.round(sumR / images.length)
    const avgG = Math.round(sumG / images.length)
    const avgB = Math.round(sumB / images.length)
    const avgA = Math.round(sumA / images.length)

    return `rgba(${avgR}, ${avgG}, ${avgB}, ${avgA / 255})`
  } catch (error) {
    return 'rgba(0, 0, 0, 1)'
  }
}

/**
 * Calculate user level
 * @param {Number} currentExp
 * @returns {{level, percentage: string}}
 */
const calculateExp = (currentExp) => {
  let level = 0

  for (const each of ExpJSON) {
    if (currentExp >= each.exp) {
      level += 1
    } else {
      break
    }
  }
  if (level > 5) {
    level = 5
  }
  const icon = require(`@src/static/level-${level}.png`)
  const result = ExpJSON[level]
  return {
    level: result?.level,
    percentage: ((currentExp / result?.exp) * 100).toFixed(1),
    required: result?.exp,
    icon
  }
}

export {
  isMobile,
  isTablet,
  isMobileDevices,
  isExtremeSmallDevices,
  serializeReduxError,
  getCSSVariable,
  getPointAfterRotate,
  randomPickFromArray,
  extractBackgroundColorFromImages,
  calculateExp
}
