import moment from 'moment'

const MESSAGES = {
  LOADING: {
    DEFAULT: 'Loading...',
    REGISTERING: 'Registering...',
    VERIFYING: 'Verifying...',
    LOGGING_OUT: 'Logging out...',
    LOGGING: 'Logging...',
    SUBMITTING_EMAIL: 'Submitting email...',
    RESETTING_PASSWORD: 'Resetting password...'
  },
  LABEL: {
    EMAIL: 'Email',
    EMAIL_CURRENT: 'Please enter your registered email address',
    PASSWORD: 'Password',
    PASSWORD_CREATE: 'Please create your password',
    PASSWORD_CONFIRM: 'Confirm Password',
    PASSWORD_CURRENT: 'Current Password',
    PASSWORD_NEW: 'New Password',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name'
  },
  PLACEHOLDER: {
    EMAIL: 'Email',
    PASSWORD: 'Password',
    PASSWORD_CONFIRM: 'Confirm Password'
  },
  VALIDATION: {
    EMAIL: 'Please enter your email address',
    PASSWORD: 'Please enter your password',
    PASSWORD_CONFIRM: 'Please confirm your password',
    PASSWORD_CONFIRM_NOT_MATCH: 'The new password that you entered do not match',
    PASSWORD_TOO_WEAK:
      'Your password is too weak. Password must contain special characters, numbers, uppercase and lowercase letters',
    FIRST_NAME: 'Please enter your first name',
    LAST_NAME: 'Please enter your last name',
    EMPTY_SELECTION: 'Please select your answer',
    ANSWER_NEED_TO_BE_YES: 'Please choose "Yes" to proceed',
    INVALID_VETERAN_OR_MOBILE_FOR_PRE_REGISTER:
      'The ACT-app is currently only available to CAF veterans with a modern mobile phone'
  },
  PROMPT: {
    PROFILE_UPDATED_SUCCESSFULLY: 'Profile updated successfully',
    PASSWORD_UPDATED_SUCCESSFULLY: 'Password updated successfully',
    LOGOUT_DEVICE_SUCCESSFULLY: 'Logout out the device you selected successfully',
    USER_UPDATED_SUCCESSFULLY: 'User updated successfully',
    USER_DELETED_SUCCESSFULLY: 'User deleted successfully',
    RESET_PASSWORD_EMAIL_SUBMITTED_SUCCESSFULLY:
      'Email has been sent to your email address associated with your account. Please click on URL in the email to reset your password.',
    QUESTION_UNLOCKED: 'Questions has been unlocked for this user'
  },
  DESIGNED_BY: `Developed by Melbourne eResearch ©${moment().year()}`
}

export { MESSAGES }
