import { createAsyncThunk } from '@reduxjs/toolkit'
import { AUTH_ACTION_TYPES } from '@store/types'
import { authApi, userApi } from '@src/apis'
import { serializeReduxError } from '@src/utils'

const login = createAsyncThunk(
  AUTH_ACTION_TYPES.LOGIN,
  async ({ email, password }) => {
    return await authApi.login({ email, password })
  },
  {
    serializeError: serializeReduxError
  }
)

const logout = createAsyncThunk(
  AUTH_ACTION_TYPES.LOGOUT,
  async () => {
    return await authApi.logout()
  },
  {
    serializeError: serializeReduxError
  }
)

const getCurrent = createAsyncThunk(
  AUTH_ACTION_TYPES.GET_CURRENT_USER,
  async () => {
    return await authApi.getCurrent()
  },
  {
    serializeError: serializeReduxError
  }
)

/**
 * Register
 */
const register = createAsyncThunk(
  AUTH_ACTION_TYPES.REGISTER,
  async ({  preRegisterData, email, password, firstName, lastName, studyId }) => {
    return await authApi.register({ preRegisterData, email, password, firstName, lastName, studyId  })
  },
  {
    serializeError: serializeReduxError
  }
)

/**
 * Update profile
 */
const updateProfile = createAsyncThunk(
  AUTH_ACTION_TYPES.UPDATE_PROFILE,
  async ({ id, body }) => {
    return await userApi.updateUser({ id, body })
  },
  {
    serializeError: serializeReduxError
  }
)

/**
 * Update password
 */
const updatePassword = createAsyncThunk(
  AUTH_ACTION_TYPES.UPDATE_PASSWORD,
  async ({ passwordCurrent, password }) => {
    return await authApi.updatePassword({ passwordCurrent, password })
  },
  {
    serializeError: serializeReduxError
  }
)

/**
 * Delete session
 */
const forceLogout = createAsyncThunk(
  AUTH_ACTION_TYPES.FORCE_LOGOUT,
  async (id) => {
    return await authApi.forceLogout(id)
  },
  {
    serializeError: serializeReduxError
  }
)

const resetPasswordRequest = createAsyncThunk(
  AUTH_ACTION_TYPES.RESET_PASSWORD_REQUEST,
  async ({ email }) => {
    return await authApi.resetPasswordRequest(email)
  },
  {
    serializeError: serializeReduxError
  }
)

const resetPassword = createAsyncThunk(
  AUTH_ACTION_TYPES.RESET_PASSWORD,
  async ({ token, body }) => {
    return await authApi.resetPassword({ token, body })
  },
  {
    serializeError: serializeReduxError
  }
)

/**
 * Delete self account
 */
const deleteAccount = createAsyncThunk(
  AUTH_ACTION_TYPES.DELETE_ACCOUNT,
  async (id) => {
    return await userApi.deleteUser(id)
  },
  {
    serializeError: serializeReduxError
  }
)

const authActions = {
  login,
  logout,
  getCurrent,
  register,
  updateProfile,
  updatePassword,
  forceLogout,
  resetPasswordRequest,
  resetPassword,
  deleteAccount
}
export { authActions }
