import React from 'react'
import './index.css'
import { Typography } from 'antd'

const ScrollUpButton = () => {
  return (
    <div className='arrow-up-container'>
      <div className='arrow-up'></div>
      <div className='arrow-up'></div>
      <Typography.Text strong style={{ marginTop: '10px', color: 'lightgrey' }}>
        Scroll Up
      </Typography.Text>
    </div>
  )
}

export default ScrollUpButton
