import React from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { MESSAGES, ROUTE_PATH } from '@src/constants'
import RootPage from '@pages/root-page'
import LoginPage from '@pages/login-page'
import AuthRoute from '@src/routes/auth-route'
import { Result, Spin } from 'antd'
import PublicRoute from '@src/routes/public-route'
import RegisterPage from '@pages/register-page'
import TokenVerificationPage from '@pages/token-verification-page'
import ProfilePage from '@pages/profile-page'
import SessionPage from '@pages/session-page'
import MobileOnlyPromptPage from '@pages/mobile-only-prompt-page'
import AdminPage from '@pages/admin-page'
import UserPage from '@pages/user-page'
import NoPermissionPromptPage from '@pages/no-permission-prompt-page'
import ResetPasswordRequestPage from '@pages/reset-password-request-page'
import ResetPasswordPage from '@pages/reset-password-page'
import PlainLanguageStatementPage from '@pages/plain-language-statement-page'
import ConsentFormPage from '@pages/consent-form-page'
import QuestionnairePage from '@pages/questionnaire-page'

const NotFoundPage = () => {
  return <Result status='404' title='404' subTitle='Sorry, the page you visited does not exist.' />
}

const RoutesWrapper = () => {
  return (
    <HashRouter fallbackElement={<Spin tip={MESSAGES.LOADING.DEFAULT} />}>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path={ROUTE_PATH.ROOT.NAME} element={<RootPage />} />
          <Route path={ROUTE_PATH.LOGIN.NAME} element={<LoginPage />} />
          <Route path={ROUTE_PATH.REGISTER.NAME} element={<RegisterPage />} />
          <Route path={ROUTE_PATH.TOKEN.NAME} element={<TokenVerificationPage />} />
          <Route
            path={ROUTE_PATH.MOBILE_ONLY_PROMPT_PAGE.NAME}
            element={<MobileOnlyPromptPage />}
          />
          <Route
            path={ROUTE_PATH.NO_PERMISSION_PROMPT_PAGE.NAME}
            element={<NoPermissionPromptPage />}
          />
          <Route
            path={ROUTE_PATH.RESET_PASSWORD_REQUEST.NAME}
            element={<ResetPasswordRequestPage />}
          />
          <Route path={ROUTE_PATH.RESET_PASSWORD.NAME} element={<ResetPasswordPage />} />
        </Route>
        <Route element={<AuthRoute />}>
          <Route path={ROUTE_PATH.ADMIN.NAME} element={<AdminPage />} />
          <Route path={ROUTE_PATH.SESSIONS.NAME} element={<UserPage />} />
          <Route path={ROUTE_PATH.SESSION.NAME} element={<SessionPage />} />
          <Route path={ROUTE_PATH.PROFILE.NAME} element={<ProfilePage />} />
          <Route path={ROUTE_PATH.CONSENT_FORM.NAME} element={<ConsentFormPage />} />
          <Route
            path={ROUTE_PATH.PLAIN_LANGUAGE_STATEMENT.NAME}
            element={<PlainLanguageStatementPage />}
          />
          <Route path={ROUTE_PATH.QUESTIONNAIRE_PAGE.NAME} element={<QuestionnairePage />} />
        </Route>
        <Route path={'*'} element={<NotFoundPage />} />
      </Routes>
    </HashRouter>
  )
}

export default RoutesWrapper
