import { COMMON_FIELDS } from './common'

const QUESTION_SET_FIELDS = {
  ...COMMON_FIELDS,
  NAME: {
    NAME: 'name'
  },
  MAX_ALLOWED_SESSION_PER_DAY: { NAME: 'maxAllowedSessionPerDay' },
  QUESTIONS: {
    NAME: 'questions',
    FIELDS: {
      ID: { NAME: '_id' },
      NAME: { NAME: 'name' },
      ORDER: { NAME: 'order' },
      TRIALS: { NAME: 'trials' },
      INTRODUCTION: { NAME: 'introduction' },
      QUESTIONNAIRE_ID: { NAME: 'questionnaireId' },
      GROUP: { NAME: 'group' },
      REQUIRED: { NAME: 'required' },
      TYPE: {
        NAME: 'type',
        ENUMS: {
          SESSION: 'session',
          FEEDBACK: 'feedback'
        }
      }
    }
  }
}

export { QUESTION_SET_FIELDS }
