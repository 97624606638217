import { BaseApi } from '@src/apis/base'

class UserApi extends BaseApi {
  /**
   * Verify token
   * @param {String} token
   * @returns {Promise<*>}
   */
  async updateUser({ id, body }) {
    try {
      const res = await this.client.put(this.endpoints.USER.USER(id), body)
      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }

  /**
   * Get users
   * @param query
   * @returns {Promise<*>}
   */
  async getUsers(query = {}) {
    const params = { ...query }
    try {
      const res = await this.client.get(this.endpoints.USER.USERS, {
        params
      })
      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }

  /**
   * Delete user
   * @param {String} id
   * @returns {Promise<*>}
   */
  async deleteUser(id) {
    try {
      await this.client.delete(this.endpoints.USER.USER(id))
      return id
    } catch (e) {
      this.errorHandler(e)
    }
  }

  /**
   * Unlock questions
   * @param id
   * @returns {Promise<*>}
   */
  async unlockQuestionnaire({ id }) {
    try {
      const res = await this.client.post(this.endpoints.USER.UNLOCK_QUESTIONS(id))
      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }
}

export { UserApi }
