import { createSlice } from '@reduxjs/toolkit'
import { REDUX_STATE } from '@src/constants'
import { tokenActions } from '@store/actions/token'

const { FIELDS } = REDUX_STATE.TOKEN

const tokenSlice = createSlice({
  name: REDUX_STATE.TOKEN.NAME,
  initialState: {
    [FIELDS.IS_VERIFIED]: false,
    [FIELDS.IS_VERIFYING]: false,
    [FIELDS.ERROR]: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(tokenActions.verifyToken.pending, (state) => {
        state[FIELDS.ERROR] = null
        state[FIELDS.IS_VERIFYING] = true
        state[FIELDS.IS_VERIFIED] = false
      })
      .addCase(tokenActions.verifyToken.fulfilled, (state) => {
        state[FIELDS.IS_VERIFYING] = false
        state[FIELDS.IS_VERIFIED] = true
      })
      .addCase(tokenActions.verifyToken.rejected, (state, action) => {
        state[FIELDS.ERROR] = action.error
        state[FIELDS.IS_VERIFYING] = false
      })
  }
})

export default tokenSlice.reducer
