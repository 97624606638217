import { COMMON_FIELDS } from './common'

const USER_FIELDS = {
  ...COMMON_FIELDS,
  FIRST_NAME: {
    NAME: 'firstName'
  },
  LAST_NAME: {
    NAME: 'lastName'
  },
  EMAIL: {
    NAME: 'email'
  },
  IS_VERIFIED: {
    NAME: 'isVerified'
  },
  PASSWORD: {
    NAME: 'password'
  },
  ROLE: {
    NAME: 'role',
    ENUMS: {
      ADMIN: 'admin',
      USER: 'user'
    }
  },
  READ_PLAIN_LANGUAGE_STATEMENT: {
    NAME: 'readPlainLanguageStatement'
  },
  READ_CONSENT_FORM: {
    NAME: 'readConsentForm'
  },
  NOTIFICATION_SETTINGS: {
    NAME: 'notificationSettings',
    FIELDS: {
      TIME: {
        NAME: 'time',
        FIELDS: {
          HOUR: { NAME: 'hour' },
          MINUTE: { NAME: 'minute' }
        }
      },
      ENABLED: { NAME: 'enabled' }
    }
  },
  PRE_REGISTER_DATA: {
    NAME: 'preRegisterData',
    FIELDS: {
      IRRITABLE_BEHAVIOUR_SCORE: { NAME: 'irritableBehaviourScore' },
      ALERTNESS_SCORE: { NAME: 'alertnessScore' },
      JUMPY_BEHAVIOR_SCORE: { NAME: 'jumpyBehaviorScore' },
      CONCENTRATION_DIFFICULTY_SCORE: { NAME: 'concentrationDifficultyScore' },
      SLEEP_DISTURBANCE_SCORE: { NAME: 'sleepDisturbanceScore' }
    }
  }
}

export { USER_FIELDS }
