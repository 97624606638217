import { createSlice } from '@reduxjs/toolkit'
import { REDUX_STATE } from '@src/constants'
import { generalActions } from '@store/actions/general'

const { FIELDS } = REDUX_STATE.GENERAL

const generalSlice = createSlice({
  name: REDUX_STATE.GENERAL.NAME,
  initialState: {
    [FIELDS.GIT_VERSION]: null,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.ERROR]: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generalActions.getGitVersion.pending, (state) => {
        state[FIELDS.ERROR] = null
        state[FIELDS.IS_GETTING] = true
      })
      .addCase(generalActions.getGitVersion.fulfilled, (state, action) => {
        state[FIELDS.GIT_VERSION] = action.payload
        state[FIELDS.IS_GETTING] = false
      })
      .addCase(generalActions.getGitVersion.rejected, (state, action) => {
        state[FIELDS.IS_GETTING] = false
        state[FIELDS.ERROR] = action.error
      })
  }
})

export default generalSlice.reducer
