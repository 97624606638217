import { Button, FloatButton } from 'antd'
import { MessageOutlined } from '@ant-design/icons'
import { getCSSVariable, isMobile } from '@src/utils'
import { CSS_VARIABLES, DATE_TIME_FORMAT, REDUX_STATE } from '@src/constants'
import RobotMessage from '@components/chat-robot/robot-message'
import React, { useEffect, useState } from 'react'
import moment from 'moment/moment'
import { useDispatch, useSelector } from 'react-redux'
import { generalActions } from '@store/actions/general'
import UserMessage from '@components/chat-robot/user-message'
import HelperOptions from '@components/chat-robot/helper-options'
import PropTypes from 'prop-types'

const ChatRobot = ({ isOpen, onClose, onOpen }) => {
  const dispatch = useDispatch()
  const apiVersion = useSelector(
    (state) => state?.[REDUX_STATE.GENERAL.NAME]?.[REDUX_STATE.GENERAL.FIELDS.GIT_VERSION]
  )
  const [messages, setMessages] = useState([])

  /**
   * Get api version
   */
  useEffect(() => {
    dispatch(generalActions.getGitVersion())
  }, [dispatch])

  /**
   * Init message
   */
  useEffect(() => {
    setMessages([
      {
        content: (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div>
              Thank you for using our website, I am <strong>offline assistant robot</strong> and
              will provide you with helpful information based on your current visiting page only
            </div>
            <div>
              UI version: <strong>{process.env.REACT_APP_GIT_VERSION}</strong>
            </div>
            <div>
              API version: <strong>{apiVersion}</strong>
            </div>
          </div>
        ),
        isRobot: true,
        time: moment().format(DATE_TIME_FORMAT)
      }
    ])
  }, [apiVersion])

  return (
    <div>
      {!isOpen && (
        <FloatButton icon={<MessageOutlined />} onClick={onOpen} style={{ marginBottom: '10px' }} />
      )}
      {isOpen && (
        <div
          style={{
            backgroundColor: 'white',
            width: '350px',
            maxWidth: '90vw',
            minHeight: '500px',
            maxHeight: '90vh',
            position: 'fixed',
            height: '600px',
            right: isMobile ? '40%' : '50px',
            bottom: isMobile ? '40%' : '50px',
            border: '1px solid lightgrey',
            borderRadius: '15px',
            overflow: 'hidden',
            transform: isMobile ? 'translateX(40%) translateY(40%)' : 'none'
          }}
        >
          {/*title*/}
          <div
            style={{
              height: '50px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0 10px',
              backgroundColor: getCSSVariable(CSS_VARIABLES.SECONDARY),
              color: 'white',
              fontWeight: '800'
            }}
          >
            <div>Assistance chat</div>
            <Button ghost={true} onClick={onClose}>
              X
            </Button>
          </div>
          {/*body*/}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              overflowY: 'scroll',
              height: 'calc(100% - 50px)',
              fontWeight: '400'
            }}
          >
            {messages?.map((each, i) => {
              if (each.isRobot) {
                return <RobotMessage message={each} key={i} />
              } else {
                return <UserMessage message={each} key={i} />
              }
            })}
            <HelperOptions
              sendMessage={(msg) => {
                setMessages([...messages, ...msg])
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

ChatRobot.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired
}

export default ChatRobot
