import { createSlice } from '@reduxjs/toolkit'
import { REDUX_STATE } from '@src/constants'
import { authActions } from '@store/actions/auth'
import { PASSPORT_SESSION_FIELDS } from '@constants/db'

const { FIELDS } = REDUX_STATE.AUTH

const authSlice = createSlice({
  name: REDUX_STATE.AUTH.NAME,
  initialState: {
    [FIELDS.USER]: null,
    [FIELDS.SESSIONS]: [],
    [FIELDS.IS_LOGGING_IN]: false,
    [FIELDS.IS_LOGGED_IN]: false,
    [FIELDS.IS_LOGGING_OUT]: false,
    [FIELDS.IS_REGISTERING]: false,
    [FIELDS.IS_UPDATING]: false,
    [FIELDS.IS_FORCING_LOGOUT]: false,
    [FIELDS.IS_VERIFYING]: true,
    [FIELDS.IS_DELETING]: false,
    [FIELDS.IS_UPDATING_PASSWORD]: false,
    [FIELDS.IS_SUBMITTING_EMAIL]: false,
    [FIELDS.IS_SUBMITTED_EMAIL]: false,
    [FIELDS.IS_RESETTING_PASSWORD]: false,
    [FIELDS.IS_RESET_PASSWORD]: false,
    [FIELDS.ERROR]: null
  },
  reducers: {
    initAuthState: (state) => {
      state[FIELDS.USER] = null
      state[FIELDS.SESSIONS] = []
      state[FIELDS.IS_LOGGING_IN] = false
      state[FIELDS.IS_LOGGED_IN] = false
      state[FIELDS.IS_LOGGING_OUT] = false
      state[FIELDS.IS_REGISTERING] = false
      state[FIELDS.IS_UPDATING] = false
      state[FIELDS.IS_FORCING_LOGOUT] = false
      state[FIELDS.IS_VERIFYING] = true
      state[FIELDS.IS_UPDATING_PASSWORD] = false
      state[FIELDS.IS_SUBMITTING_EMAIL] = false
      state[FIELDS.IS_SUBMITTED_EMAIL] = false
      state[FIELDS.IS_RESETTING_PASSWORD] = false
      state[FIELDS.IS_RESET_PASSWORD] = false
      state[FIELDS.ERROR] = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(authActions.login.pending, (state) => {
        state[FIELDS.USER] = null
        state[FIELDS.ERROR] = null
        state[FIELDS.IS_LOGGED_IN] = false
        state[FIELDS.IS_LOGGING_IN] = true
      })
      .addCase(authActions.login.fulfilled, (state, action) => {
        state[FIELDS.USER] = action.payload?.user
        state[FIELDS.SESSIONS] = action.payload?.sessions
        state[FIELDS.IS_LOGGED_IN] = true
        state[FIELDS.IS_LOGGING_IN] = false
      })
      .addCase(authActions.login.rejected, (state, action) => {
        state[FIELDS.IS_LOGGING_IN] = false
        state[FIELDS.IS_LOGGED_IN] = false
        state[FIELDS.ERROR] = action.error
      })
      .addCase(authActions.logout.pending, (state) => {
        state[FIELDS.ERROR] = null
        state[FIELDS.IS_LOGGING_OUT] = true
      })
      .addCase(authActions.logout.fulfilled, (state) => {
        state[FIELDS.USER] = null
        state[FIELDS.IS_LOGGING_OUT] = false
        state[FIELDS.IS_LOGGED_IN] = false
      })
      .addCase(authActions.logout.rejected, (state, action) => {
        state[FIELDS.IS_LOGGING_OUT] = false
        state[FIELDS.ERROR] = action.error
      })
      .addCase(authActions.getCurrent.pending, (state) => {
        state[FIELDS.ERROR] = null
        state[FIELDS.IS_VERIFYING] = true
      })
      .addCase(authActions.getCurrent.fulfilled, (state, action) => {
        state[FIELDS.USER] = action.payload?.user
        state[FIELDS.SESSIONS] = action.payload?.sessions
        state[FIELDS.IS_LOGGED_IN] = true
        state[FIELDS.IS_VERIFYING] = false
      })
      .addCase(authActions.getCurrent.rejected, (state, action) => {
        state[FIELDS.IS_LOGGED_IN] = false
        state[FIELDS.IS_VERIFYING] = false
        state[FIELDS.USER] = null
        state[FIELDS.ERROR] = action.error
      })
      .addCase(authActions.register.pending, (state) => {
        state[FIELDS.IS_REGISTERING] = true
        state[FIELDS.ERROR] = null
      })
      .addCase(authActions.register.fulfilled, (state) => {
        state[FIELDS.IS_REGISTERING] = false
      })
      .addCase(authActions.register.rejected, (state, action) => {
        state[FIELDS.IS_REGISTERING] = false
        state[FIELDS.ERROR] = action.error
      })
      .addCase(authActions.updateProfile.pending, (state) => {
        state[FIELDS.ERROR] = null
        state[FIELDS.IS_UPDATING] = true
      })
      .addCase(authActions.updateProfile.fulfilled, (state, action) => {
        state[FIELDS.USER] = action.payload
        state[FIELDS.IS_UPDATING] = false
      })
      .addCase(authActions.updateProfile.rejected, (state, action) => {
        state[FIELDS.IS_UPDATING] = false
        state[FIELDS.ERROR] = action.error
      })
      .addCase(authActions.updatePassword.pending, (state) => {
        state[FIELDS.ERROR] = null
        state[FIELDS.IS_UPDATING_PASSWORD] = true
      })
      .addCase(authActions.updatePassword.fulfilled, (state) => {
        state[FIELDS.IS_UPDATING_PASSWORD] = false
      })
      .addCase(authActions.updatePassword.rejected, (state, action) => {
        state[FIELDS.IS_UPDATING_PASSWORD] = false
        state[FIELDS.ERROR] = action.error
      })
      .addCase(authActions.forceLogout.pending, (state) => {
        state[FIELDS.ERROR] = null
        state[FIELDS.IS_FORCING_LOGOUT] = true
      })
      .addCase(authActions.forceLogout.fulfilled, (state, action) => {
        state[FIELDS.IS_FORCING_LOGOUT] = false
        state[FIELDS.SESSIONS] = state[FIELDS.SESSIONS]?.filter(
          (each) => each[PASSPORT_SESSION_FIELDS.ID.NAME] !== action.payload
        )
      })
      .addCase(authActions.forceLogout.rejected, (state, action) => {
        state[FIELDS.IS_FORCING_LOGOUT] = false
        state[FIELDS.ERROR] = action.error
      })
      .addCase(authActions.resetPasswordRequest.pending, (state) => {
        state[FIELDS.IS_SUBMITTING_EMAIL] = true
        state[FIELDS.ERROR] = null
      })
      .addCase(authActions.resetPasswordRequest.fulfilled, (state, action) => {
        state[FIELDS.IS_SUBMITTING_EMAIL] = false
        state[FIELDS.IS_SUBMITTED_EMAIL] = true
        state[FIELDS.ERROR] = action.error
      })
      .addCase(authActions.resetPasswordRequest.rejected, (state, action) => {
        state[FIELDS.IS_SUBMITTING_EMAIL] = false
        state[FIELDS.ERROR] = action.error
      })
      .addCase(authActions.resetPassword.pending, (state) => {
        state[FIELDS.IS_RESETTING_PASSWORD] = true
        state[FIELDS.ERROR] = null
      })
      .addCase(authActions.resetPassword.fulfilled, (state, action) => {
        state[FIELDS.IS_RESETTING_PASSWORD] = false
        state[FIELDS.IS_RESET_PASSWORD] = true
        state[FIELDS.ERROR] = action.error
      })
      .addCase(authActions.resetPassword.rejected, (state, action) => {
        state[FIELDS.IS_RESETTING_PASSWORD] = false
        state[FIELDS.ERROR] = action.error
      })
      .addCase(authActions.deleteAccount.pending, (state) => {
        state[FIELDS.IS_DELETING] = true
        state[FIELDS.ERROR] = null
      })
      .addCase(authActions.deleteAccount.fulfilled, (state) => {
        state[FIELDS.IS_DELETING] = false
        state[FIELDS.IS_LOGGED_IN] = false
        state[FIELDS.USER] = null
        state[FIELDS.SESSIONS] = []
      })
      .addCase(authActions.deleteAccount.rejected, (state, action) => {
        state[FIELDS.IS_DELETING] = false
        state[FIELDS.ERROR] = action.error
      })
  }
})

export const { initAuthState } = authSlice.actions
export default authSlice.reducer
