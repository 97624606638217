const ENDPOINTS = {
  AUTH: {
    LOGIN: '/auth/login',
    LOGOUT: 'auth/logout',
    CURRENT: '/auth/current',
    REGISTER: '/auth/register',
    UPDATE_PASSWORD: '/auth/update-password',
    FORCE_LOGOUT: (id) => `/auth/sessions/${id}`,
    RESET_PASSWORD_REQUEST: '/auth/reset-password',
    RESET_PASSWORD: (token) => `/auth/reset-password/${token}`
  },
  FEEDBACK_FORM: {
    FEEDBACK_FORMS: '/feedback-forms'
  },
  FILE: {
    FILE: (id) => `/files/${id}`,
    FILES: '/files'
  },
  TOKEN: {
    VERIFY: '/tokens/verify-token'
  },
  USER: {
    USERS: '/users',
    USER: (id) => `/users/${id}`,
    UNLOCK_QUESTIONS: (id) => `/users/${id}/unlockQuestions`
  },
  QUESTION_SET: {
    QUESTION_SETS: '/question-sets',
    QUESTION: (id) => `/question-sets/${id}/question`
  },
  RECORD: {
    RECORD: (id) => `/records/${id}`,
    RECORDS: '/records',
    RESPONSES: (id) => `/records/${id}/responses`
  },
  GENERAL: {
    GIT_VERSION: '/general/version'
  }
}

export { ENDPOINTS }
