import { BaseApi } from '@src/apis/base'

class GeneralApi extends BaseApi {
  /**
   * Get API version
   * @returns {Promise<*>}
   */
  async getGitVersion() {
    try {
      const res = await this.client.get(this.endpoints.GENERAL.GIT_VERSION)
      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }
}

export { GeneralApi }
