import { createSlice } from '@reduxjs/toolkit'
import { REDUX_STATE } from '@src/constants'
import { recordActions } from '@store/actions/record'

const { FIELDS, NAME } = REDUX_STATE.RECORD

const recordSlice = createSlice({
  name: NAME,
  initialState: {
    [FIELDS.SESSION]: null,
    [FIELDS.RECORDS]: [],
    [FIELDS.RECORD]: null,
    [FIELDS.IS_INITIALIZING_RECORD]: false,
    [FIELDS.IS_GETTING_RECORD]: false,
    [FIELDS.IS_UPDATING_ANSWER]: false,
    [FIELDS.AVAILABLE_SESSION_ID]: null,
    [FIELDS.ERROR]: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(recordActions.initRecord.pending, (state) => {
        state[FIELDS.ERROR] = null
        state[FIELDS.IS_INITIALIZING_RECORD] = true
      })
      .addCase(recordActions.initRecord.fulfilled, (state) => {
        state[FIELDS.IS_INITIALIZING_RECORD] = false
      })
      .addCase(recordActions.initRecord.rejected, (state, action) => {
        state[FIELDS.ERROR] = action.error
        state[FIELDS.IS_INITIALIZING_RECORD] = false
      })
      .addCase(recordActions.getRecords.pending, (state) => {
        state[FIELDS.ERROR] = null
        state[FIELDS.IS_GETTING_RECORD] = true
      })
      .addCase(recordActions.getRecords.fulfilled, (state, action) => {
        state[FIELDS.IS_GETTING_RECORD] = false
        state[FIELDS.RECORDS] = action.payload
      })
      .addCase(recordActions.getRecords.rejected, (state, action) => {
        state[FIELDS.ERROR] = action.error
        state[FIELDS.IS_GETTING_RECORD] = false
      })
      .addCase(recordActions.getRecord.pending, (state) => {
        state[FIELDS.ERROR] = null
        state[FIELDS.IS_GETTING_RECORD] = true
        state[FIELDS.AVAILABLE_SESSION_ID] = null
      })
      .addCase(recordActions.getRecord.fulfilled, (state, action) => {
        state[FIELDS.IS_GETTING_RECORD] = false
        state[FIELDS.RECORD] = action.payload
      })
      .addCase(recordActions.getRecord.rejected, (state, action) => {
        state[FIELDS.ERROR] = action.error
        state[FIELDS.IS_GETTING_RECORD] = false
      })
      .addCase(recordActions.updateResponse.pending, (state) => {
        state[FIELDS.ERROR] = null
        state[FIELDS.IS_UPDATING_ANSWER] = true
      })
      .addCase(recordActions.updateResponse.fulfilled, (state, action) => {
        state[FIELDS.IS_UPDATING_ANSWER] = false
        state[FIELDS.RECORD] = action.payload
      })
      .addCase(recordActions.updateResponse.rejected, (state, action) => {
        state[FIELDS.ERROR] = action.error
        state[FIELDS.IS_UPDATING_ANSWER] = false
      })
  }
})

export default recordSlice.reducer
