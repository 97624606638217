import styled from 'styled-components'

const TouchWrapper = styled.div`
  position: absolute;
  width: 44px;
  height: 44px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ClickMeButton = styled.div`
  background: radial-gradient(circle at center, yellow, yellow);
  border: none;
  color: white;
  width: 15px;
  height: 15px;
  font-size: 36px;
  font-weight: bold;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  position: relative;
  box-shadow:
    0 5px 15px rgba(0, 0, 0, 0.3),
    inset 0 0 10px rgba(255, 255, 255, 0.5);
  transition:
    transform 0.2s,
    box-shadow 0.2s;
  text-align: center;
  line-height: 100px;
  animation: pulse 1.5s infinite;

  @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow:
        0 5px 15px rgba(0, 0, 0, 0.3),
        inset 0 0 10px rgba(255, 255, 255, 0.5);
    }
    50% {
      transform: scale(1.3);
      box-shadow:
        0 7px 20px rgba(0, 0, 0, 0.4),
        inset 0 0 12px rgba(255, 255, 255, 0.5);
    }

    100% {
      transform: scale(1);
      box-shadow:
        0 5px 15px rgba(0, 0, 0, 0.3),
        inset 0 0 10px rgba(255, 255, 255, 0.5);
    }
  }
`

export { ClickMeButton, TouchWrapper }
