import React from 'react'
import { Form, Switch, TimePicker } from 'antd'
import { TIME_FORMAT } from '@src/constants'

const NotificationSettingForm = () => {
  return (
    <div style={{ textAlign: 'left' }}>
      <Form.Item
        name={'notificationTime'}
        label={'When do you want to receive notification each day'}
      >
        <TimePicker inputReadOnly format={TIME_FORMAT} changeOnScroll={true} />
      </Form.Item>
      <Form.Item name={'enableNotification'} label={'Do you want to receive notification'}>
        <Switch />
      </Form.Item>
    </div>
  )
}

export default NotificationSettingForm
