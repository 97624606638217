import axios from 'axios'
import QS from 'qs'

const baseURL = '/api'

const paramsSerializer = (params) => QS.stringify(params, { arrayFormat: 'comma' })

const client = axios.create({
  baseURL,
  paramsSerializer
})

// client.interceptors.request.use(async () => {
//
// })

export default client
