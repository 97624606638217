import React, { useEffect, useRef, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import ImageWithButtons from '@components/session-page/image-with-buttons'
import { useDispatch, useSelector } from 'react-redux'
import { CSS_VARIABLES, REDUX_STATE, ROUTE_PATH, ROUTE_QUERY_PARAMS } from '@src/constants'
import { FILE_FIELDS, QUESTION_SET_FIELDS, RECORD_FIELDS } from '@constants/db'
import { questionSetActions } from '@store/actions/question-set'
import { Typography } from 'antd'
import { HoverCardWrapper } from '@src/styles/wrapper'
import _ from 'lodash'
import { extractBackgroundColorFromImages, getCSSVariable, isMobileDevices } from '@src/utils'
import BasicActionButtons from '@components/basic-action-buttons'
import StringToHTMLWrapper from '@components/string-to-html-wrapper'
import { recordActions } from '@store/actions/record'
import { useQuery, useShowMessages } from '@src/utils/hooks'

const STEPS = {
  INTRO: 'INTRO',
  LOAD_QUESTION: 'LOAD_QUESTION',
  DISPLAY_QUESTION: 'DISPLAY_QUESTION',
  COMPLETE: 'COMPLETE'
}

const SessionPage = () => {
  const params = useParams()
  const navigate = useNavigate()
  const query = useQuery()
  const questionId = params.id
  const dispatch = useDispatch()
  const recordId = query.get(ROUTE_QUERY_PARAMS.RECORD.NAME)
  const isGettingQuestion = useSelector(
    (state) =>
      state?.[REDUX_STATE.QUESTION_SET.NAME]?.[REDUX_STATE.QUESTION_SET.FIELDS.IS_GETTING_QUESTION]
  )
  const questionSet = useSelector(
    (state) =>
      state?.[REDUX_STATE.QUESTION_SET.NAME]?.[REDUX_STATE.QUESTION_SET.FIELDS.QUESTION_SET]
  )
  const isUpdatingResponse = useSelector(
    (state) => state?.[REDUX_STATE.RECORD.NAME]?.[REDUX_STATE.RECORD.FIELDS.IS_UPDATING_ANSWER]
  )
  const currentQuestion = _.find(questionSet?.[QUESTION_SET_FIELDS.QUESTIONS.NAME], {
    [QUESTION_SET_FIELDS.QUESTIONS.FIELDS.ID.NAME]: questionId
  })
  const currentQuestionType = currentQuestion?.[QUESTION_SET_FIELDS.QUESTIONS.FIELDS.TYPE.NAME]
  const pairPictures = useSelector(
    (state) => state?.[REDUX_STATE.QUESTION_SET.NAME]?.[REDUX_STATE.QUESTION_SET.FIELDS.SESSION]
  )
  const error = useSelector(
    (state) => state?.[REDUX_STATE.QUESTION_SET.NAME]?.[REDUX_STATE.QUESTION_SET.FIELDS.ERROR]
  )
  const record = useSelector(
    (state) => state?.[REDUX_STATE.RECORD.NAME]?.[REDUX_STATE.RECORD.FIELDS.RECORD]
  )
  const recordError = useSelector(
    (state) => state?.[REDUX_STATE.RECORD.NAME]?.[REDUX_STATE.RECORD.FIELDS.ERROR]
  )
  const responseForCurrentQuestion = _.find(record?.[RECORD_FIELDS.RESPONSES.NAME], {
    [RECORD_FIELDS.RESPONSES.FIELDS.QUESTION_ID.NAME]: questionId
  })
  const responseStatus = responseForCurrentQuestion?.[RECORD_FIELDS.RESPONSES.FIELDS.STATUS.NAME]

  const isFinished = responseStatus === RECORD_FIELDS.RESPONSES.FIELDS.STATUS.ENUMS.COMPLETED
  const isOpen = responseStatus === RECORD_FIELDS.RESPONSES.FIELDS.STATUS.ENUMS.OPEN
  const isInProgress = responseStatus === RECORD_FIELDS.RESPONSES.FIELDS.STATUS.ENUMS.IN_PROGRESS

  const [step, setStep] = useState(null)
  const [bgColor, setBgColor] = useState('white')
  const responseTime = useRef(0)
  const prevIsGettingQuestion = useRef(isGettingQuestion)
  const questionSetId = questionSet?.[QUESTION_SET_FIELDS.ID.NAME]

  const prevIsPostingAnswer = useRef(isUpdatingResponse)

  /**
   * Get or create session in record after read intro
   */
  useEffect(() => {
    if (recordId && questionId) {
      dispatch(recordActions.getRecord({ id: recordId, questionId }))
    }
  }, [dispatch, questionId, recordId])

  /**
   * Get question set for introduction text
   */
  useEffect(() => {
    if (isOpen) {
      dispatch(questionSetActions.getQuestionSet({ questionId }))
    }
  }, [dispatch, questionId, isOpen])

  /**
   * If user already started answer, then display picture directly without intro
   */
  useEffect(() => {
    if (!responseStatus || isOpen) {
      setStep(STEPS.INTRO)
    } else {
      setStep(STEPS.LOAD_QUESTION)
    }
  }, [isOpen, responseStatus])

  /**
   * Load next question if non finish
   */
  useEffect(() => {
    if (isInProgress && !recordError && prevIsPostingAnswer.current && !isUpdatingResponse) {
      setStep(STEPS.LOAD_QUESTION)
    }
    prevIsPostingAnswer.current = isUpdatingResponse
  }, [dispatch, recordError, isUpdatingResponse, isInProgress, questionSetId, questionId])

  /**
   * Get questions
   */
  useEffect(() => {
    if (step === STEPS.LOAD_QUESTION) {
      dispatch(questionSetActions.getQuestion({ id: questionSetId, questionId }))
    }
  }, [dispatch, questionId, step, questionSetId])

  /**
   * Show completion page if finish
   */
  useEffect(() => {
    if (isFinished) {
      setStep(STEPS.COMPLETE)
    }
  }, [isFinished])

  /**
   * Reset response timer when get new pictures
   */
  useEffect(() => {
    let timeout
    if (!error && prevIsGettingQuestion.current && !isGettingQuestion) {
      responseTime.current = 0
      timeout = setTimeout(() => {
        setStep(STEPS.DISPLAY_QUESTION)
      }, 500)
    }
    prevIsGettingQuestion.current = isGettingQuestion

    return () => {
      clearTimeout(timeout)
    }
  }, [isGettingQuestion, error])

  /**
   * Set up bg color based on image loaded
   */
  useEffect(() => {
    if (!_.isEmpty(pairPictures)) {
      const getBgColor = async () => {
        const color = await extractBackgroundColorFromImages(pairPictures)
        setBgColor(color)
      }
      getBgColor()
    }
  }, [pairPictures])

  /**
   * Log response time
   */
  useEffect(() => {
    let timeInterval

    if (step === STEPS.DISPLAY_QUESTION && !_.isEmpty(pairPictures)) {
      timeInterval = setInterval(() => {
        responseTime.current += 1
      }, 1000)
    }

    return () => {
      clearInterval(timeInterval)
    }
  }, [pairPictures, step])

  useShowMessages({ error: error || recordError })

  /**
   * Handle go back
   */
  const handleGoBack = () => {
    navigate(ROUTE_PATH.SESSIONS.NAME)
  }

  /**
   * Handle response
   */
  const handleResponse = (file) => {
    if (recordId && questionId) {
      dispatch(
        recordActions.updateResponse({
          recordId,
          questionId,
          combinationId: file?.combinationId,
          answer: {
            responseTime: responseTime.current,
            emotion: file?.emotion,
            position: file?.position
          }
        })
      )
    }
  }

  /**
   * Confirm introduction
   */
  const handleConfirmIntro = () => {
    setStep(STEPS.LOAD_QUESTION)
  }

  if (!recordId || error || recordError) {
    return <Navigate to={ROUTE_PATH.SESSIONS.NAME} />
  } else {
    return (
      <div>
        <HoverCardWrapper
          style={{
            margin: '0 auto',
            width: isMobileDevices ? '78vw' : '400px',
            minWidth: '320px',
            overflow: 'hidden',
            height: `calc(100vh - ${getCSSVariable(CSS_VARIABLES.HEADER_HEIGHT)} - ${getCSSVariable(CSS_VARIABLES.FOOTER_HEIGHT)})`
          }}
        >
          <BasicActionButtons
            onCancel={handleGoBack}
            style={{ marginBottom: '10px' }}
            isShowOk={
              (currentQuestionType === QUESTION_SET_FIELDS.QUESTIONS.FIELDS.TYPE.ENUMS.SESSION &&
                step === STEPS.INTRO) ||
              currentQuestionType === QUESTION_SET_FIELDS.QUESTIONS.FIELDS.TYPE.ENUMS.FEEDBACK
            }
            okText={'Next'}
            onOk={handleConfirmIntro}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              alignItems: 'center',
              padding: '5px',
              height: 'calc(100% - 32px)',
              overflow: 'hidden'
            }}
          >
            {step === STEPS.INTRO && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%'
                }}
              >
                <StringToHTMLWrapper
                  htmlString={
                    currentQuestion?.[QUESTION_SET_FIELDS.QUESTIONS.FIELDS.INTRODUCTION.NAME]
                  }
                />
              </div>
            )}
            {(isGettingQuestion || step === STEPS.LOAD_QUESTION) && (
              <div
                style={{
                  backgroundColor: bgColor,
                  height: `calc(100vh - ${getCSSVariable(CSS_VARIABLES.HEADER_HEIGHT)} - ${getCSSVariable(CSS_VARIABLES.FOOTER_HEIGHT)} - 32px - 80px)`,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  fontSize: '100px'
                }}
              >
                +
              </div>
            )}
            {step === STEPS.DISPLAY_QUESTION &&
              pairPictures?.map((each) => {
                return (
                  <ImageWithButtons
                    key={each?.[FILE_FIELDS.ID.NAME]}
                    style={{
                      overflow: 'hidden'
                    }}
                    file={each}
                    handleClickDot={() => handleResponse(each)}
                  />
                )
              })}
            {isFinished && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '25px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%'
                }}
              >
                <Typography.Title level={3}>Congratulations!</Typography.Title>
                <Typography.Paragraph>
                  Please click back, and complete the questionnaire.
                </Typography.Paragraph>
                <Typography.Paragraph>
                  You are then finished for today.
                </Typography.Paragraph>
              </div>
            )}
          </div>
        </HoverCardWrapper>
      </div>
    )
  }
}

export default SessionPage
