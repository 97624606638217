import { createAsyncThunk } from '@reduxjs/toolkit'
import { TOKEN_ACTION_TYPES } from '@store/types'
import { tokenApi } from '@src/apis'

const verifyToken = createAsyncThunk(TOKEN_ACTION_TYPES.VERIFY, async (token) => {
  return await tokenApi.verify(token)
})

const tokenActions = {
  verifyToken
}
export { tokenActions }
