import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CSS_VARIABLES, MESSAGES, REDUX_STATE, ROUTE_PATH, ROUTE_STATES } from '@src/constants'
import { Button, Dropdown, Image, Layout, Spin, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import APP_ICON from '@src/static/icon.svg'
import { getCSSVariable } from '@src/utils'
import { authActions } from '@store/actions/auth'
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined
} from '@ant-design/icons'
import { USER_FIELDS } from '@constants/db'
import { usePageAccessControl, usePreventZoomingIn, useShowMessages } from '@src/utils/hooks'
import HelperButtons from '@components/helper-buttons'

const AuthRoute = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isLoggedIn = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.IS_LOGGED_IN]
  )
  const error = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.ERROR]
  )
  const isVerifying = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.IS_VERIFYING]
  )
  const user = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.USER]
  )
  const isLoggingOut = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.IS_LOGGING_OUT]
  )
  const role = user?.[USER_FIELDS.ROLE.NAME]
  const location = useLocation()
  const path = location?.pathname

  const [headerCollapsed, setHeaderCollapsed] = useState(true)

  const prevPath = useRef(null)
  const prevIsLoggingOut = useRef(isLoggingOut)
  const hasReadPlainLanguageStatement = user?.[USER_FIELDS.READ_PLAIN_LANGUAGE_STATEMENT.NAME]
  const hasReadConsentForm = user?.[USER_FIELDS.READ_CONSENT_FORM.NAME]

  const USER_MENU_ITEMS = [
    {
      label: (
        <Typography.Text strong>Welcome, {user?.[USER_FIELDS.FIRST_NAME.NAME]}</Typography.Text>
      ),
      key: '1',
      disabled: true
    },
    {
      label: 'Profile',
      key: '2',
      icon: <UserOutlined />,
      onClick: () =>
        navigate(ROUTE_PATH.PROFILE.NAME, { state: { [ROUTE_STATES.REDIRECTED_FROM]: path } })
    },
    {
      label: 'Logout',
      key: '3',
      icon: <LogoutOutlined />,
      danger: true,
      onClick: () => dispatch(authActions.logout())
    }
  ]

  usePreventZoomingIn()

  useShowMessages({ error })

  /**
   * Get current when route change
   */
  useEffect(() => {
    if (prevPath.current !== path) {
      dispatch(authActions.getCurrent())
    }
    prevPath.current = path
  }, [path, dispatch])

  /**
   * If user try to use PC visit a mobile only page, the prompt page will return
   * only work when user logged in
   */
  usePageAccessControl({ role, isVerifying, isLoggedIn })

  /**
   * Redirect to plain language statement or consent form if not read
   */
  useEffect(() => {
    if (role === USER_FIELDS.ROLE.ENUMS.USER && isLoggedIn) {
      if (!hasReadPlainLanguageStatement) {
        navigate(ROUTE_PATH.PLAIN_LANGUAGE_STATEMENT.NAME)
      } else if (!hasReadConsentForm) {
        navigate(ROUTE_PATH.CONSENT_FORM.NAME)
      }
    }
  }, [navigate, hasReadPlainLanguageStatement, isLoggedIn, role, hasReadConsentForm])

  /**
   * Redirect to root page after logging out
   */
  useEffect(() => {
    if (!error && !isLoggingOut && prevIsLoggingOut.current) {
      navigate(ROUTE_PATH.ROOT.NAME)
    }
    prevIsLoggingOut.current = isLoggingOut
  }, [navigate, error, isLoggingOut])

  /**
   * Toggle header collapse status
   */
  const toggleHeaderCollapsed = () => {
    setHeaderCollapsed(!headerCollapsed)
  }

  if (isVerifying || isLoggingOut) {
    return (
      <Spin
        spinning={isVerifying || isLoggingOut}
        fullscreen={true}
        tip={
          (isVerifying && MESSAGES.LOADING.VERIFYING) ||
          (isLoggingOut && MESSAGES.LOADING.LOGGING_OUT)
        }
      />
    )
  } else if (isLoggedIn) {
    return (
      <Layout style={{ width: '100vw', minHeight: '100vh' }}>
        <HelperButtons />
        <Layout.Header
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            gap: '10px',
            justifyContent: 'space-between',
            position: 'fixed',
            width: '100%',
            zIndex: getCSSVariable(CSS_VARIABLES.HIGH_Z_INDEX)
          }}
        >
          <Image
            src={APP_ICON}
            alt={'logo'}
            preview={false}
            style={{
              width: getCSSVariable(CSS_VARIABLES.HEADER_HEIGHT),
              height: getCSSVariable(CSS_VARIABLES.HEADER_HEIGHT),
              objectFit: 'cover',
              cursor: 'pointer'
            }}
            onClick={() =>
              navigate(
                role === USER_FIELDS.ROLE.ENUMS.ADMIN
                  ? ROUTE_PATH.ADMIN.NAME
                  : ROUTE_PATH.SESSIONS.NAME
              )
            }
          />
          <Typography.Text strong={true}>Attention Control Training App</Typography.Text>
          <Dropdown
            menu={{ items: USER_MENU_ITEMS }}
            trigger={['click']}
            overlayStyle={{ width: '100%' }}
            placement={'bottom'}
          >
            <Button
              onClick={toggleHeaderCollapsed}
              style={{
                height: '100%',
                marginLeft: 'auto',
                color: 'black',
                border: '3px solid white'
              }}
              ghost={true}
            >
              {headerCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>
          </Dropdown>
        </Layout.Header>
        <Layout
          style={{
            padding: '0 5px 5px'
          }}
        >
          <Layout.Content
            style={{
              overflow: 'hidden',
              padding: '5px',
              marginTop: getCSSVariable(CSS_VARIABLES.HEADER_HEIGHT)
            }}
          >
            <Outlet />
          </Layout.Content>
          <Layout.Footer style={{ height: getCSSVariable(CSS_VARIABLES.FOOTER_HEIGHT) }}>
            {MESSAGES.DESIGNED_BY}
          </Layout.Footer>
        </Layout>
      </Layout>
    )
  } else {
    return null
  }
}

export default AuthRoute
