import { createAsyncThunk } from '@reduxjs/toolkit'
import { QUESTION_SET_ACTION_TYPES } from '@store/types'
import { questionSetApi } from '@src/apis'
import { serializeReduxError } from '@src/utils'

const getQuestion = createAsyncThunk(
  QUESTION_SET_ACTION_TYPES.GET_QUESTION,
  async ({ id, sessionId }) => {
    return await questionSetApi.getQuestion({ id, sessionId })
  },
  {
    serializeError: serializeReduxError
  }
)

const getQuestionSet = createAsyncThunk(
  QUESTION_SET_ACTION_TYPES.GET_QUESTION_SET,
  async (params) => {
    return await questionSetApi.getQuestionSet(params)
  },
  {
    serializeError: serializeReduxError
  }
)

const questionSetActions = {
  getQuestion,
  getQuestionSet
}
export { questionSetActions }
