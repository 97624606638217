import React, { useEffect, useRef, useState } from 'react'
import { Form, Progress, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  CSS_VARIABLES,
  MESSAGES,
  REDUX_STATE,
  ROUTE_PATH,
  ROUTE_STATES,
  TIME_FORMAT
} from '@src/constants'
import { USER_FIELDS } from '@constants/db'
import { HoverCardWrapper } from '@src/styles/wrapper'
import { authActions } from '@store/actions/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  ContactsFilled,
  LeftOutlined,
  MobileFilled,
  NotificationFilled,
  SafetyCertificateFilled
} from '@ant-design/icons'
import { usePreventZoomingIn, useShowMessages } from '@src/utils/hooks'
import { calculateExp, getCSSVariable, isMobile } from '@src/utils'
import PersonalInformationForm from '@components/profile-page/personal-information-form'
import SecuritySettingForm from '@components/profile-page/security-setting-form'
import RegisteredDevices from '@components/profile-page/registered-devices'
import NotificationSettingForm from '@components/profile-page/notification-setting-form'
import BasicActionButtons from '@components/basic-action-buttons'
import moment from 'moment'

const MODES = {
  DEFAULT: { NAME: 'DEFAULT', TITLE: null, ICON: null },
  PROFILE: {
    NAME: 'PROFILE',
    TITLE: 'Personal information',
    ICON: <ContactsFilled style={{ fontSize: '20px' }} />
  },
  SECURITY: {
    NAME: 'SECURITY',
    TITLE: 'Sign-in & security',
    ICON: <SafetyCertificateFilled style={{ fontSize: '20px' }} />
  },
  REGISTERED_MOBILE: {
    NAME: 'REGISTERED_MOBILE',
    TITLE: 'Logged-in device(s)',
    ICON: <MobileFilled style={{ fontSize: '20px' }} />
  },
  NOTIFICATION: {
    NAME: 'NOTIFICATION',
    TITLE: 'Notification setting(s)',
    ICON: <NotificationFilled style={{ fontSize: '20px' }} />
  },
}

const SAMPLE_EXP = 5

const ProfilePage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const pathStates = location.state
  const [mode, setMode] = useState(MODES.DEFAULT.NAME)
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const error = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.ERROR]
  )
  const isUpdating = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.IS_UPDATING]
  )
  const isUpdatingPassword = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.IS_UPDATING_PASSWORD]
  )
  const user = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.USER]
  )
  const role = user?.[USER_FIELDS.ROLE.NAME]

  const [message, setMessage] = useState({ success: null, error: null })

  const prevIsUpdating = useRef(isUpdating)
  const prevIsUpdatingPassword = useRef(isUpdatingPassword)
  const exp = calculateExp(SAMPLE_EXP)

  usePreventZoomingIn()

  /**
   * Display message after personal info updated
   */
  useEffect(() => {
    if (!isUpdating && prevIsUpdating.current && !error) {
      setMessage({
        ...message,
        success: { message: MESSAGES.PROMPT.PROFILE_UPDATED_SUCCESSFULLY }
      })
    }
    prevIsUpdating.current = isUpdating
  }, [error, isUpdating, message])

  /**
   * Display message after password updated
   */
  useEffect(() => {
    if (!isUpdatingPassword && prevIsUpdatingPassword.current && !error) {
      setMessage({
        ...message,
        success: { message: MESSAGES.PROMPT.PASSWORD_UPDATED_SUCCESSFULLY }
      })
    }
    prevIsUpdatingPassword.current = isUpdatingPassword
  }, [error, isUpdatingPassword, message])

  useShowMessages({ error: error || message.error, success: message.success })

  /**
   * Handle switch between modes
   * @param {String} mode
   */
  const handleSwitchMode = (mode) => {
    setMode(mode)
    form?.resetFields()
  }

  /**
   * Handle click back button
   */
  const handleClickBack = () => {
    if (mode === MODES.DEFAULT.NAME) {
      if (
        !pathStates?.[ROUTE_STATES.REDIRECTED_FROM] ||
        [ROUTE_PATH.LOGIN.NAME, ROUTE_PATH.ROOT.NAME].includes(
          pathStates?.[ROUTE_STATES.REDIRECTED_FROM]
        )
      ) {
        navigate(
          role === USER_FIELDS.ROLE.ENUMS.ADMIN ? ROUTE_PATH.ADMIN.NAME : ROUTE_PATH.SESSIONS.NAME
        )
      } else {
        navigate(-1)
      }
    } else {
      form.resetFields()
      setMode(MODES.DEFAULT.NAME)
    }
  }

  /**
   * Handle click save button
   */
  const handleClickSave = () => {
    form.submit()
  }

  /**
   * Handle submit form
   * @param {Object} values
   */
  const onFinish = (values) => {
    if (mode === MODES.PROFILE.NAME) {
      dispatch(authActions.updateProfile({ id: user?.[USER_FIELDS.ID.NAME], body: values }))
    } else if (mode === MODES.SECURITY.NAME) {
      dispatch(authActions.updatePassword(values))
    } else if (mode === MODES.NOTIFICATION.NAME) {
      const time = values?.notificationTime

      const hour = moment(time).hour()
      const minutes = moment(time).minutes()

      dispatch(
        authActions.updateProfile({
          id: user?.[USER_FIELDS.ID.NAME],
          body: {
            [USER_FIELDS.NOTIFICATION_SETTINGS.NAME]: {
              [USER_FIELDS.NOTIFICATION_SETTINGS.FIELDS.ENABLED.NAME]: values?.enableNotification,
              [USER_FIELDS.NOTIFICATION_SETTINGS.FIELDS.TIME.NAME]: {
                [USER_FIELDS.NOTIFICATION_SETTINGS.FIELDS.TIME.FIELDS.HOUR.NAME]: hour,
                [USER_FIELDS.NOTIFICATION_SETTINGS.FIELDS.TIME.FIELDS.MINUTE.NAME]: minutes
              }
            }
          }
        })
      )
    }
  }

  return (
    <div style={{ height: '100%' }}>
      <HoverCardWrapper
        style={{
          margin: '0 auto',
          width: isMobile ? '100%' : '500px'
        }}
      >
        <BasicActionButtons
          okText={'Save'}
          cancelText={'Back'}
          cancelIcon={<LeftOutlined />}
          onCancel={handleClickBack}
          onOk={handleClickSave}
          isShowOk={![MODES.DEFAULT.NAME, MODES.REGISTERED_MOBILE.NAME].includes(mode)}
          style={{ marginBottom: '20px' }}
        />
        {/*Default*/}
        {mode === MODES.DEFAULT.NAME && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Typography.Title level={2}>
              <strong style={{ fontSize: 'inherit' }}>Welcome</strong>,{' '}
              {user?.[USER_FIELDS.FIRST_NAME.NAME]}
            </Typography.Title>
            {user?.[USER_FIELDS.ROLE.NAME] === USER_FIELDS.ROLE.ENUMS.USER && (
              <>
                <Typography.Text strong>
                  Lv {exp.level} ({SAMPLE_EXP}/{exp.required}) <Progress percent={exp.percentage} />
                </Typography.Text>
                <Typography.Text type={'success'}>
                  Keep working, you are not far away from next level !{' '}
                </Typography.Text>
              </>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: isMobile ? 'row' : 'column',
                gap: '5px',
                flexWrap: 'wrap'
              }}
            >
              {Object.keys(MODES).map((eachMode) => {
                if (eachMode === MODES.DEFAULT.NAME) return null
                const current = MODES[eachMode]
                if (
                    current?.NAME === MODES.NOTIFICATION.NAME &&
                  role === USER_FIELDS.ROLE.ENUMS.ADMIN
                )
                  return null
                return (
                  <HoverCardWrapper
                    style={{
                      height: 'inherit',
                      cursor: 'pointer',
                      flex: isMobile ? '1 1 calc(50% - 10px)' : '1 1 100%'
                    }}
                    onClick={() => handleSwitchMode(current?.NAME)}
                    key={current?.NAME}
                  >
                    {current?.ICON}
                    <div>{current?.TITLE}</div>
                  </HoverCardWrapper>
                )
              })}
            </div>
          </div>
        )}
        {/*Title*/}
        {MODES?.[mode]?.NAME !== MODES.DEFAULT.NAME && (
          <div
            style={{
              fontSize: `${getCSSVariable(CSS_VARIABLES.FONT_SIZE_HEADER_3)}px`,
              marginBottom: '15px',
              display: 'block',
              fontWeight: 600
            }}
          >
            {MODES?.[mode]?.TITLE}
          </div>
        )}
        {/*Body*/}
        {![MODES.REGISTERED_MOBILE.NAME, MODES.DEFAULT.NAME].includes(mode) && (
          <Form
            layout='vertical'
            initialValues={{
              [USER_FIELDS.FIRST_NAME.NAME]: user?.[USER_FIELDS.FIRST_NAME.NAME],
              [USER_FIELDS.LAST_NAME.NAME]: user?.[USER_FIELDS.LAST_NAME.NAME],
              [USER_FIELDS.EMAIL.NAME]: user?.[USER_FIELDS.EMAIL.NAME],
              get notificationTime() {
                const time =
                  user?.[USER_FIELDS.NOTIFICATION_SETTINGS.NAME]?.[
                    USER_FIELDS.NOTIFICATION_SETTINGS.FIELDS.TIME.NAME
                  ]
                const hour = time?.[USER_FIELDS.NOTIFICATION_SETTINGS.FIELDS.TIME.FIELDS.HOUR.NAME]
                const minutes =
                  time?.[USER_FIELDS.NOTIFICATION_SETTINGS.FIELDS.TIME.FIELDS.MINUTE.NAME]
                return moment(`${hour}:${minutes}`, TIME_FORMAT)
              },
              get enableNotification() {
                return user?.[USER_FIELDS.NOTIFICATION_SETTINGS.NAME]?.[
                  USER_FIELDS.NOTIFICATION_SETTINGS.FIELDS.ENABLED.NAME
                ]
              }
            }}
            onFinish={onFinish}
            form={form}
            scrollToFirstError
          >
            {/*Profile*/}
            {mode === MODES.PROFILE.NAME && <PersonalInformationForm />}
            {/*Security*/}
            {mode === MODES.SECURITY.NAME && <SecuritySettingForm />}
            {/*Notification*/}
            {mode === MODES.NOTIFICATION.NAME && <NotificationSettingForm />}
          </Form>
        )}
        {/*Registered devices*/}
        {mode === MODES.REGISTERED_MOBILE.NAME && <RegisteredDevices />}
      </HoverCardWrapper>
    </div>
  )
}

export default ProfilePage
