import { createSlice } from '@reduxjs/toolkit'
import { REDUX_STATE } from '@src/constants'
import { questionSetActions } from '@store/actions/question-set'

const { FIELDS, NAME } = REDUX_STATE.QUESTION_SET

const questionSetSlice = createSlice({
  name: NAME,
  initialState: {
    [FIELDS.IS_GETTING_QUESTION]: false,
    [FIELDS.SESSION]: null,
    [FIELDS.QUESTION_SET]: [],
    [FIELDS.IS_GETTING_QUESTION_SET]: false,
    [FIELDS.ERROR]: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(questionSetActions.getQuestion.pending, (state) => {
        state[FIELDS.ERROR] = null
        state[FIELDS.IS_GETTING_QUESTION] = true
      })
      .addCase(questionSetActions.getQuestion.fulfilled, (state, action) => {
        state[FIELDS.IS_GETTING_QUESTION] = false
        state[FIELDS.SESSION] = action.payload
      })
      .addCase(questionSetActions.getQuestion.rejected, (state, action) => {
        state[FIELDS.ERROR] = action.error
        state[FIELDS.IS_GETTING_QUESTION] = false
      })
      .addCase(questionSetActions.getQuestionSet.pending, (state) => {
        state[FIELDS.ERROR] = null
        state[FIELDS.IS_GETTING_QUESTION_SET] = true
      })
      .addCase(questionSetActions.getQuestionSet.fulfilled, (state, action) => {
        state[FIELDS.IS_GETTING_QUESTION_SET] = false
        state[FIELDS.QUESTION_SET] = action.payload
      })
      .addCase(questionSetActions.getQuestionSet.rejected, (state, action) => {
        state[FIELDS.ERROR] = action.error
        state[FIELDS.IS_GETTING_QUESTION_SET] = false
      })
  }
})

export default questionSetSlice.reducer
