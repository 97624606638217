import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Navigate, useSearchParams } from 'react-router-dom'
import { MESSAGES, QUERY_PARAMS, REDUX_STATE, ROUTE_PATH } from '@src/constants'
import React, { useEffect, useState } from 'react'
import { tokenActions } from '@store/actions/token'
import { HoverCardWrapper } from '@src/styles/wrapper'
import { Button, Form, Input, Result, Spin, Typography } from 'antd'
import { USER_FIELDS } from '@constants/db'
import { passwordStrength } from 'check-password-strength'
import PassWordStrengthIndicator from '@components/password-strength-indicator'
import { authActions } from '@store/actions/auth'

const ResetPasswordPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [form] = Form.useForm()
  const error = useSelector(
    (state) => state?.[REDUX_STATE.TOKEN.NAME]?.[REDUX_STATE.TOKEN.FIELDS.ERROR]
  )
  const isResettingPassword = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.IS_RESETTING_PASSWORD]
  )

  const isResetPassword = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.IS_RESET_PASSWORD]
  )
  const [passStrength, setPassStrength] = useState(null)
  const token = searchParams.get(QUERY_PARAMS.TOKEN)

  /**
   * Verify token
   */
  useEffect(() => {
    if (token) {
      dispatch(tokenActions.verifyToken(token))
    }
  }, [dispatch, token])

  /**
   * Go to root if token invalid
   */
  useEffect(() => {
    let timeout
    if (error) {
      setTimeout(() => {
        navigate(ROUTE_PATH.ROOT.NAME)
      }, 3000)
    }

    if (isResetPassword) {
      setTimeout(() => {
        navigate(ROUTE_PATH.LOGIN.NAME)
      }, 3000)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [error, isResetPassword, navigate])

  const onFinish = (values) => {
    dispatch(authActions.resetPassword({ token, body: { password: values.password } }))
  }

  if (!token) {
    return <Navigate to={ROUTE_PATH.ROOT.NAME} />
  } else {
    return (
      <div style={{ height: '100%' }}>
        <Spin tip={MESSAGES.LOADING.RESETTING_PASSWORD} spinning={isResettingPassword}>
          {!error && !isResetPassword && (
            <HoverCardWrapper style={{ width: '400px', margin: '20vh auto' }}>
              <Typography.Title>Reset Password</Typography.Title>
              <Form layout='vertical' form={form} onFinish={onFinish}>
                <Form.Item
                  label={MESSAGES.LABEL.PASSWORD_CREATE}
                  name={USER_FIELDS.PASSWORD.NAME}
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.VALIDATION.PASSWORD
                    },
                    () => ({
                      validator(_, value) {
                        const strength = passwordStrength(value).value
                        setPassStrength(strength)
                        if (['Medium', 'Strong'].includes(strength)) {
                          return Promise.resolve()
                        } else {
                          return Promise.reject(MESSAGES.VALIDATION.PASSWORD_TOO_WEAK)
                        }
                      }
                    })
                  ]}
                >
                  <Input placeholder={MESSAGES.PLACEHOLDER.PASSWORD} type={'password'}></Input>
                </Form.Item>
                {form.getFieldValue(USER_FIELDS.PASSWORD.NAME) && (
                  <PassWordStrengthIndicator
                    strength={passStrength}
                    style={{ margin: '15px auto' }}
                  />
                )}
                <Form.Item
                  label={MESSAGES.LABEL.PASSWORD_CONFIRM}
                  name={'passwordConfirmed'}
                  dependencies={[USER_FIELDS.PASSWORD.NAME]}
                  hasFeedback={true}
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.VALIDATION.PASSWORD_CONFIRM
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue(USER_FIELDS.PASSWORD.NAME) === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(MESSAGES.VALIDATION.PASSWORD_CONFIRM_NOT_MATCH)
                        )
                      }
                    })
                  ]}
                >
                  <Input placeholder={MESSAGES.PLACEHOLDER.PASSWORD_CONFIRM} type={'password'} />
                </Form.Item>
                <Form.Item>
                  <Button type='primary' htmlType='submit' style={{ width: '100%' }}>
                    Submit
                  </Button>
                </Form.Item>
                <Button onClick={() => navigate(ROUTE_PATH.LOGIN.NAME)}>Back</Button>
              </Form>
            </HoverCardWrapper>
          )}
        </Spin>
        {isResetPassword && !error && (
          <HoverCardWrapper style={{ width: '400px', margin: '20vh auto' }}>
            <Result
              status='success'
              title={MESSAGES.PROMPT.PASSWORD_UPDATED_SUCCESSFULLY}
              subTitle='Enjoy your day at our platform.'
              extra={[
                <Button
                  type='primary'
                  key='login-btn'
                  onClick={() => navigate(ROUTE_PATH.LOGIN.NAME)}
                >
                  Back
                </Button>
              ]}
            />
          </HoverCardWrapper>
        )}
        {error && (
          <HoverCardWrapper style={{ width: '400px', margin: '20vh auto' }}>
            <Result
              status='error'
              title='Token invalid'
              subTitle='Please try again later'
              extra={[
                <Button
                  type='primary'
                  key='login-btn'
                  onClick={() => navigate(ROUTE_PATH.LOGIN.NAME)}
                >
                  Back
                </Button>
              ]}
            />
          </HoverCardWrapper>
        )}
      </div>
    )
  }
}

export default ResetPasswordPage
