import { createAsyncThunk } from '@reduxjs/toolkit'
import { FEEDBACK_FORM_ACTION_TYPES } from '@store/types'
import { serializeReduxError } from '@src/utils'
import { feedbackFormApi } from '@src/apis'

const getFeedbackForms = createAsyncThunk(
  FEEDBACK_FORM_ACTION_TYPES.GET_FEEDBACK_FORMS,
  async () => {
    return await feedbackFormApi.getFeedbackForms()
  },
  {
    serializeError: serializeReduxError
  }
)

const feedbackFormActions = {
  getFeedbackForms
}

export { feedbackFormActions }
