/**
 * Extract res.data
 * @param res
 * @returns {*}
 */
const extractResponse = (res) => {
  return res.data.data
}

/**
 * Handler error from backend
 * @param err
 */
const errorHandler = (err) => {
  if (err.response) {
    const message = err.response.data?.error || err?.response?.data
    const status = err?.response?.status
    const redirect = err?.response?.data?.redirect || null
    const newError = new Error(message)
    newError.status = status
    newError.redirect = redirect
    throw newError
  } else {
    throw err
  }
}

export { extractResponse, errorHandler }
