import client from '@src/apis/client'
import { ENDPOINTS } from '@src/constants'
import { errorHandler, extractResponse } from '@src/apis/utils'

class BaseApi {
  constructor() {
    this.client = client
    this.endpoints = ENDPOINTS
    this.errorHandler = errorHandler
    this.extractResponse = extractResponse
  }
}

export { BaseApi }
