import { useEffect, useRef } from 'react'
import { isMobileDevices } from '@src/utils/index'
import { App } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTE_PATH, ROUTE_STATES } from '@src/constants'
import _ from 'lodash'
import { PERMISSIONS } from '@constants/permissions'
import { useDispatch } from 'react-redux'
import { initAuthState } from '@store/reducers/auth'

/**
 * Handle error display
 * @param error
 * @param success
 */
const useShowMessages = ({ error, success }) => {
  const dispatch = useDispatch()
  const { message } = App.useApp()
  const location = useLocation()
  const previousError = useRef(error)
  const prevSuccess = useRef(success)
  const pathname = location.pathname

  const navigate = useNavigate()

  useEffect(() => {
    if (
      error &&
      previousError.current !== error &&
      (error?.url?.includes(pathname) || !error?.url)
    ) {
      message.destroy(error?.message)
      message.error({ key: error?.message, content: error?.message })

      if (error?.status === 401) {
        dispatch(initAuthState())
        setTimeout(() => {
          navigate(ROUTE_PATH.LOGIN.NAME, { state: { [ROUTE_STATES.REDIRECTED_FROM]: pathname } })
        }, 1000)
      } else if (error?.redirect) {
        setTimeout(() => {
          navigate(error?.redirect)
        }, 1000)
      }
    }
    previousError.current = error
  }, [dispatch, error, navigate, message, pathname])

  useEffect(() => {
    if (success && prevSuccess.current !== success) {
      message.destroy(success?.message)
      message.success({ key: success?.message, content: success?.message })
      if (success?.redirect) {
        setTimeout(() => {
          navigate(success?.redirect)
        }, 1000)
      }
    }
    prevSuccess.current = success
  }, [success, navigate, message])
}

/**
 * Use to prevent page zoom in on mobile
 */
const usePreventZoomingIn = () => {
  const metaTag = useRef(null)
  useEffect(() => {
    if (isMobileDevices) {
      metaTag.current = document.createElement('meta')
      metaTag.current.name = 'viewport'
      metaTag.current.content =
        'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
      document.head.appendChild(metaTag.current)
    }

    return () => {
      if (metaTag.current) {
        document.head.removeChild(metaTag.current)
      }
    }
  }, [])
}

/**
 * Used to control user access to specific pages
 * If user try to use PC visit a mobile only page, the prompt page will return
 * only work when user logged in
 */
const usePageAccessControl = ({ role, isVerifying, isLoggedIn }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const path = location?.pathname

  /**
   * Find route object based on pathname
   * @param pathname
   */
  const findRoute = (pathname) => {
    const paramPattern = /:id/

    return _.find(ROUTE_PATH, (route) => {
      const routeRegex = new RegExp(`^${route.NAME.replace(paramPattern, '([^/]+)')}$`)
      return routeRegex.test(pathname)
    })
  }

  useEffect(() => {
    if (!isVerifying && isLoggedIn) {
      const route = findRoute(path)
      // eslint-disable-next-line no-undef
      const permissions = route?.PERMISSION_BY_ROLE(role, true)
      if (!permissions?.[PERMISSIONS.CAN_ACCESS_BY_CURRENT_ROLE]) {
        return navigate(ROUTE_PATH.NO_PERMISSION_PROMPT_PAGE.NAME)
      } else if (!permissions?.[PERMISSIONS.CAN_ACCESS_BY_CURRENT_DEVICE]) {
        return navigate(ROUTE_PATH.MOBILE_ONLY_PROMPT_PAGE.NAME)
      }
    }
  }, [path, role, navigate, isVerifying, isLoggedIn])
}

/**
 * Get query params in url
 * @returns {module:url.URLSearchParams}
 */
const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export { usePreventZoomingIn, useShowMessages, usePageAccessControl, useQuery }
