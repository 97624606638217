import React from 'react'
import { Avatar } from 'antd'
import RobotIcon from '@src/static/robot-icon.png'
import PropTypes from 'prop-types'

const RobotMessage = ({ message }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
        padding: '15px',
        alignItems: 'center',
        textAlign: 'left'
      }}
    >
      <Avatar shape='square' draggable={false} size={40} src={RobotIcon} />
      <div
        style={{
          border: '1px solid lightgrey',
          padding: '5px',
          width: '100%',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          gap: '5px'
        }}
      >
        <div style={{ fontWeight: '700', color: 'lightgrey' }}>{message?.time}</div>
        <div
          style={{
            overflowX: 'hidden'
          }}
        >
          {message?.content}
        </div>
      </div>
    </div>
  )
}

RobotMessage.propTypes = {
  message: PropTypes.shape({
    time: PropTypes.string,
    content: PropTypes.object
  })
}

export default RobotMessage
