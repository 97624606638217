import React from 'react'
import { Button, Checkbox, Form, Input, Spin, Typography } from 'antd'
import { MESSAGES, REDUX_STATE, ROUTE_PATH, ROUTE_STATES } from '@src/constants'
import { USER_FIELDS } from '@constants/db'
import { HoverCardWrapper } from '@src/styles/wrapper'
import { useDispatch, useSelector } from 'react-redux'
import { authActions as userActions } from '@store/actions/auth'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useShowMessages } from '@src/utils/hooks'

const LoginPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const pathStates = location.state
  const redirectedFrom = pathStates?.[ROUTE_STATES.REDIRECTED_FROM]
  const isLoggedIn = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.IS_LOGGED_IN]
  )
  const error = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.ERROR]
  )
  const isLoggingIn = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.IS_LOGGING_IN]
  )
  const user = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.USER]
  )
  const role = user?.[USER_FIELDS.ROLE.NAME]

  useShowMessages({ error })

  /**
   * handle login
   * @param values
   */
  const onFinish = (values) => {
    dispatch(userActions.login(values))
  }

  if (isLoggedIn) {
    return (
      <Navigate
        to={
          redirectedFrom ||
          (role === USER_FIELDS.ROLE.ENUMS.ADMIN ? ROUTE_PATH.ADMIN.NAME : ROUTE_PATH.SESSIONS.NAME)
        }
      />
    )
  } else {
    return (
      <HoverCardWrapper
        style={{
          width: '350px',
          height: `fit-content`,
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)'
        }}
      >
        <Spin
          tip={MESSAGES.LOADING.LOGGING}
          spinning={isLoggingIn}
          fullscreen={true}
          size={'large'}
          style={{ height: '100%', width: '100%' }}
        />
        <Typography.Title>Log in</Typography.Title>
        <Form layout='vertical' onFinish={onFinish}>
          <Form.Item
            label={MESSAGES.LABEL.EMAIL}
            name={USER_FIELDS.EMAIL.NAME}
            rules={[
              {
                required: true,
                message: MESSAGES.VALIDATION.EMAIL
              }
            ]}
          >
            <Input placeholder={MESSAGES.PLACEHOLDER.EMAIL}></Input>
          </Form.Item>
          <Form.Item
            label={MESSAGES.LABEL.PASSWORD}
            name={USER_FIELDS.PASSWORD.NAME}
            rules={[
              {
                required: true,
                message: MESSAGES.VALIDATION.PASSWORD
              }
            ]}
          >
            <Input placeholder={MESSAGES.PLACEHOLDER.PASSWORD} type='password' />
          </Form.Item>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
            <Form.Item name='remember' valuePropName='checked' noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            <Link to={ROUTE_PATH.RESET_PASSWORD_REQUEST.NAME} rel={'noopener noreferrer'}>
              Forgot password?
            </Link>
          </div>
          <Form.Item>
            <Button type='primary' htmlType='submit' style={{ width: '100%' }}>
              Login
            </Button>
          </Form.Item>

          <Button onClick={() => navigate(ROUTE_PATH.ROOT.NAME)}>Back</Button>
        </Form>
      </HoverCardWrapper>
    )
  }
}

export default LoginPage
