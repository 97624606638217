import { BaseApi } from '@src/apis/base'

class TokenApi extends BaseApi {
  /**
   * Verify token
   * @param {String} token
   * @returns {Promise<*>}
   */
  async verify(token) {
    try {
      const res = await this.client.post(this.endpoints.TOKEN.VERIFY, {
        token
      })
      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }
}

export { TokenApi }
