import React from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'

const BasicActionButtons = ({
  okText = 'Confirm',
  cancelText = 'Back',
  cancelIsDanger = false,
  okDisabled = false,
  isShowOk = true,
  isShowCancel = true,
  style,
  cancelIcon,
  onOk,
  onCancel
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: isShowCancel ? 'space-between' : 'flex-end',
        alignItems: 'center',
        height: '32px',
        ...style
      }}
    >
      {isShowCancel && (
        <Button onClick={onCancel} icon={cancelIcon} danger={cancelIsDanger}>
          {cancelText}
        </Button>
      )}
      {isShowOk && (
        <Button onClick={onOk} type={'primary'} disabled={okDisabled}>
          {okText}
        </Button>
      )}
    </div>
  )
}

BasicActionButtons.propTypes = {
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  isShowOk: PropTypes.bool,
  isShowCancel: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  cancelIsDanger: PropTypes.bool,
  okDisabled: PropTypes.bool,
  style: PropTypes.object,
  cancelIcon: PropTypes.object
}

export default BasicActionButtons
