import React from 'react'
import { HoverCardWrapper } from '@src/styles/wrapper'
import { HighlightTwoTone, PictureTwoTone, UserOutlined } from '@ant-design/icons'
import ImageLabelling from '@components/admin-page/image-labelling'
import { useQuery } from '@src/utils/hooks'
import { ROUTE_PATH, ROUTE_QUERY_PARAMS } from '@src/constants'
import { useNavigate } from 'react-router-dom'
import UserManagement from '@components/admin-page/user-management'

const AdminPage = () => {
  const navigate = useNavigate()
  const query = useQuery()
  const tab = query.get(ROUTE_QUERY_PARAMS.TAB.NAME)
  const isShowImageLabel = tab === ROUTE_QUERY_PARAMS.TAB.ENUMS.IMAGE_LABELING
  const isShowUserManagement = tab === ROUTE_QUERY_PARAMS.TAB.ENUMS.USERS_MANAGEMENT

  /**
   * Toggle image label component
   */
  const toggleImageLabelling = () => {
    if (isShowImageLabel) {
      navigate(ROUTE_PATH.ADMIN.NAME)
    } else {
      query.set(ROUTE_QUERY_PARAMS.TAB.NAME, ROUTE_QUERY_PARAMS.TAB.ENUMS.IMAGE_LABELING)
      navigate({ pathname: ROUTE_PATH.ADMIN.NAME, search: query.toString() })
    }
  }

  /**
   * Toggle user management component
   */
  const toggleUserManagement = () => {
    if (isShowUserManagement) {
      navigate(ROUTE_PATH.ADMIN.NAME)
    } else {
      query.set(ROUTE_QUERY_PARAMS.TAB.NAME, ROUTE_QUERY_PARAMS.TAB.ENUMS.USERS_MANAGEMENT)
      navigate({ pathname: ROUTE_PATH.ADMIN.NAME, search: query.toString() })
    }
  }

  if (isShowUserManagement) {
    return <UserManagement toggleDisplay={toggleUserManagement} />
  } else if (isShowImageLabel) {
    return <ImageLabelling toggleDisplay={toggleImageLabelling} />
  } else {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', fontWeight: 'bold' }}>
        <HoverCardWrapper
          style={{ width: '100%', height: '100px', cursor: 'pointer' }}
          onClick={toggleImageLabelling}
        >
          Label images
          <div
            style={{ display: 'flex', flexDirection: 'row', gap: '15px', justifyContent: 'center' }}
          >
            <HighlightTwoTone style={{ fontSize: '30px' }} />
            <PictureTwoTone style={{ fontSize: '30px' }} />
          </div>
        </HoverCardWrapper>
        <HoverCardWrapper
          style={{ width: '100%', height: '100px', cursor: 'pointer' }}
          onClick={toggleUserManagement}
        >
          Manage users
          <div>
            <UserOutlined style={{ fontSize: '30px' }} />
          </div>
        </HoverCardWrapper>
      </div>
    )
  }
}

export default AdminPage
