import {
  getCSSVariable,
  getPointAfterRotate,
  isMobile,
  isMobileDevices,
  randomPickFromArray
} from '@src/utils'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {ClickMeButton, TouchWrapper} from '@src/styles/buttons'
import { FILE_FIELDS } from '@constants/db'
import { Image } from 'antd'
import { CSS_VARIABLES } from '@src/constants'

const DIRECTIONS = {
  UP: 0,
  DOWN: 180,
  LEFT: -90,
  RIGHT: 90
}
const TIME_BETWEEN_DOT_AND_IMAGE = 300

const ImageWithButtons = ({ file, isRandomizeRotation = false, handleClickDot, style }) => {
  const fileId = file?.[FILE_FIELDS.ID.NAME]

  const IMAGE_CONTAINER_ID = `image-with-buttons-container-${fileId}`

  const containerRef = useRef(null)
  const [direction, setDirection] = useState(null)
  const src = file?.url
  const dots = file?.dots
  const imageOriginalHeight = file?.[FILE_FIELDS.EXTRA.NAME]?.[FILE_FIELDS.EXTRA.FIELDS.HEIGHT.NAME]
  const imageOriginalWidth = file?.[FILE_FIELDS.EXTRA.NAME]?.[FILE_FIELDS.EXTRA.FIELDS.WIDTH.NAME]
  const imageContainer = document.getElementById(IMAGE_CONTAINER_ID)
  const [isInit, setIsInit] = useState(false)

  /**
   * Random value for direction
   */
  useEffect(() => {
    if (isRandomizeRotation) {
      const randomDirection = randomPickFromArray({ array: Object.keys(DIRECTIONS), num: 1 })
      setDirection(DIRECTIONS[randomDirection?.[0]])
    }
  }, [src, isRandomizeRotation])

  /**
   * Detect if image container rendered
   */
  useEffect(() => {
    let timeout
    const imageContainer = document.getElementById(IMAGE_CONTAINER_ID)
    // Function to check if the element is rendered
    const checkElementRendered = () => {
      if (imageContainer) {
        timeout = setTimeout(() => {
          setIsInit(true)
        }, TIME_BETWEEN_DOT_AND_IMAGE)
      }
    }

    // Check if the element is rendered on component mount
    checkElementRendered()

    // Optionally, observe the DOM for changes and check if the element is rendered
    const observer = new MutationObserver(() => {
      checkElementRendered()
    })

    observer.observe(document.body, { childList: true, subtree: true })

    // Clean up the observer on component unmount
    return () => {
      observer.disconnect()
      clearTimeout(timeout)
    }
  }, [IMAGE_CONTAINER_ID])

  return (
    <div
      style={{
        ...style,
        position: 'relative'
      }}
      ref={containerRef}
    >
      <Image
        src={src}
        preview={false}
        style={{
          width: 'auto',
          // button height, padding, gap
          height: `calc((100vh - 32px - 24px - 24px - ${getCSSVariable(CSS_VARIABLES.HEADER_HEIGHT)} - ${getCSSVariable(CSS_VARIABLES.FOOTER_HEIGHT)} - 10px - 10px) / 2)`,
          maxWidth: isMobile ? '78vw' : '400px',
          display: 'block',
          transformOrigin: isRandomizeRotation ? 'center center' : 'default',
          transform: isRandomizeRotation ? `rotate(${direction}deg)` : 'default',
          objectFit: 'cover'
        }}
        id={IMAGE_CONTAINER_ID}
      />

      {isInit &&
        dots?.map((eachPoint) => {
          const imageSize = {
            width: imageContainer.clientWidth,
            height: imageContainer.clientHeight
          }

          // the original points before operation, like rotate
          let basePoints

          if (isRandomizeRotation) {
            // Calculate new point coordinators when rotate
            basePoints = getPointAfterRotate({
              point: eachPoint,
              centerX: imageOriginalWidth / 2,
              centerY: imageOriginalHeight / 2,
              rotateDegree: direction
            })
          } else {
            basePoints = eachPoint
          }

          const newPoints = [
            (basePoints?.[0] / imageOriginalWidth) * imageSize.width,
            (basePoints?.[1] / imageOriginalHeight) * imageSize.height
          ]

          return (
            <TouchWrapper
              key={eachPoint}
              onClick={!isMobileDevices ? handleClickDot : undefined}
              onTouchEnd={isMobileDevices ? handleClickDot : undefined}
              style={{
                left: `${newPoints?.[0]}px`,
                top: `${newPoints?.[1]}px`,
              }}
            >
              <ClickMeButton />
            </TouchWrapper>
          )
        })}
    </div>
  )
}

ImageWithButtons.propTypes = {
  file: PropTypes.object.isRequired,
  handleClickDot: PropTypes.func,
  isRandomizeRotation: PropTypes.bool,
  style: PropTypes.object
}

export default ImageWithButtons
