import React, { useEffect, useRef } from 'react'
import { CSS_VARIABLES, REDUX_STATE, ROUTE_PATH, ROUTE_QUERY_PARAMS } from '@src/constants'
import { getCSSVariable, isMobileDevices } from '@src/utils'
import BasicActionButtons from '@components/basic-action-buttons'
import { HoverCardWrapper } from '@src/styles/wrapper'
import { useNavigate, useParams } from 'react-router-dom'
import { Form } from 'antd'
import QuestionnaireForm from '@components/session-page/questionnaire-form'
import { useQuery, useShowMessages } from '@src/utils/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { recordActions } from '@store/actions/record'

const QuestionnairePage = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()
  const query = useQuery()
  const recordId = query.get(ROUTE_QUERY_PARAMS.RECORD.NAME)
  const questionId = query.get(ROUTE_QUERY_PARAMS.QUESTION.NAME)
  const questionnaireId = params.id

  const [form] = Form.useForm()

  /**
   * Get or create session in record after read intro
   */
  useEffect(() => {
    if (recordId && questionId) {
      dispatch(recordActions.getRecord({ id: recordId, questionId }))
    }
  }, [dispatch, questionId, recordId])

  const error = useSelector(
    (state) => state?.[REDUX_STATE.RECORD.NAME]?.[REDUX_STATE.RECORD.FIELDS.ERROR]
  )
  const isSubmitting = useSelector(
    (state) => state?.[REDUX_STATE.RECORD.NAME]?.[REDUX_STATE.RECORD.FIELDS.IS_UPDATING_ANSWER]
  )
  const prevIsSubmitting = useRef(isSubmitting)

  useEffect(() => {
    if (!error && !isSubmitting && prevIsSubmitting.current) {
      navigate(ROUTE_PATH.SESSIONS.NAME)
    }
    prevIsSubmitting.current = isSubmitting
  }, [isSubmitting, error, navigate])

  useShowMessages({ error })

  /**
   * Handle go back
   */
  const handleGoBack = () => {
    navigate(ROUTE_PATH.SESSIONS.NAME)
  }

  return (
    <div>
      {/*<Spin*/}
      {/*  spinning={isGettingQuestion}*/}
      {/*  fullscreen={true}*/}
      {/*  tip={MESSAGES.LOADING.GENERATING_IMAGE}*/}
      {/*/>*/}
      <HoverCardWrapper
        style={{
          margin: '0 auto',
          width: isMobileDevices ? '78vw' : '400px',
          minWidth: '320px',
          overflow: 'hidden',
          height: `calc(100vh - ${getCSSVariable(CSS_VARIABLES.HEADER_HEIGHT)} - ${getCSSVariable(CSS_VARIABLES.FOOTER_HEIGHT)})`
        }}
      >
        <BasicActionButtons
          onCancel={handleGoBack}
          style={{ marginBottom: '10px' }}
          okText={'Submit'}
          onOk={() => form.submit()}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            alignItems: 'center',
            padding: '5px',
            height: 'calc(100% - 32px)',
            overflow: 'hidden'
          }}
        >
          <QuestionnaireForm
            form={form}
            recordId={recordId}
            questionId={questionId}
            questionnaireId={questionnaireId}
          />
        </div>
      </HoverCardWrapper>
    </div>
  )
}

export default QuestionnairePage
