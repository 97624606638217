import { createAsyncThunk } from '@reduxjs/toolkit'
import { RECORD_ACTION_TYPES } from '@store/types'
import { recordApi } from '@src/apis'
import { serializeReduxError } from '@src/utils'

const initRecord = createAsyncThunk(
  RECORD_ACTION_TYPES.INIT_RECORD,
  async ({ questionSetId }) => {
    return await recordApi.initRecord({ questionSetId })
  },
  {
    serializeError: serializeReduxError
  }
)

const getRecords = createAsyncThunk(
  RECORD_ACTION_TYPES.GET_RECORDS,
  async () => {
    return await recordApi.getRecords()
  },
  {
    serializeError: serializeReduxError
  }
)

const getRecord = createAsyncThunk(
  RECORD_ACTION_TYPES.GET_RECORD,
  async ({ id, questionId }) => {
    return await recordApi.getRecord({ id, questionId })
  },
  {
    serializeError: serializeReduxError
  }
)

const updateResponse = createAsyncThunk(
  RECORD_ACTION_TYPES.UPDATE_RESPONSE,
  async ({ recordId, questionId, combinationId, answer, status }) => {
    return await recordApi.updateResponse({ recordId, questionId, combinationId, answer, status })
  },
  {
    serializeError: serializeReduxError
  }
)

const recordActions = {
  initRecord,
  getRecords,
  getRecord,
  updateResponse
}
export { recordActions }
