import React from 'react'
import { HoverCardWrapper } from '@src/styles/wrapper'
import { QUESTION_SET_FIELDS } from '@constants/db'
import { FileTextTwoTone } from '@ant-design/icons'
import { generatePath, useNavigate } from 'react-router-dom'
import { ROUTE_PATH, ROUTE_QUERY_PARAMS } from '@src/constants'
import { useQuery } from '@src/utils/hooks'
import PropTypes from 'prop-types'

const FeedbackBriefCard = ({ question, recordId, isDisable }) => {
  const navigate = useNavigate()
  const query = useQuery()
  const questionId = question?.[QUESTION_SET_FIELDS.QUESTIONS.FIELDS.ID.NAME]
  const questionnaireId = question?.[QUESTION_SET_FIELDS.QUESTIONS.FIELDS.QUESTIONNAIRE_ID.NAME]

  const handleClick = () => {
    if (questionId && recordId && questionnaireId) {
      const path = generatePath(ROUTE_PATH.QUESTIONNAIRE_PAGE.NAME, { id: questionnaireId })
      query.set(ROUTE_QUERY_PARAMS.RECORD.NAME, recordId)
      query.set(ROUTE_QUERY_PARAMS.QUESTION.NAME, questionId)
      navigate({ pathname: path, search: query.toString() })
    }
  }
  return (
    <HoverCardWrapper
      disabled={isDisable}
      style={{ cursor: 'pointer', width: '60vw' }}
      onClick={handleClick}
    >
      <div style={{ display: 'flex', gap: '10px' }}>
        <FileTextTwoTone style={{ fontSize: '25px' }} />
        <strong>{question?.[QUESTION_SET_FIELDS.QUESTIONS.FIELDS.NAME.NAME]}</strong>
      </div>
    </HoverCardWrapper>
  )
}

FeedbackBriefCard.propTypes = {
  question: PropTypes.object,
  isDisable: PropTypes.bool,
  recordId: PropTypes.string
}
export default FeedbackBriefCard
