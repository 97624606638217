import React, { useEffect } from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { MESSAGES, QUERY_PARAMS, REDUX_STATE, ROUTE_PATH } from '@src/constants'
import { useDispatch, useSelector } from 'react-redux'
import { tokenActions } from '@store/actions/token'
import { Button, Result, Spin } from 'antd'
import { HoverCardWrapper } from '@src/styles/wrapper'
import { useShowMessages } from '@src/utils/hooks'

const TokenVerificationPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isVerifying = useSelector(
    (state) => state?.[REDUX_STATE.TOKEN.NAME]?.[REDUX_STATE.TOKEN.FIELDS.IS_VERIFYING]
  )
  const error = useSelector(
    (state) => state?.[REDUX_STATE.TOKEN.NAME]?.[REDUX_STATE.TOKEN.FIELDS.ERROR]
  )
  const isVerified = useSelector(
    (state) => state?.[REDUX_STATE.TOKEN.NAME]?.[REDUX_STATE.TOKEN.FIELDS.IS_VERIFIED]
  )
  const [searchParams] = useSearchParams()
  const token = searchParams.get(QUERY_PARAMS.TOKEN)

  /**
   * Verify token
   */
  useEffect(() => {
    if (token) {
      dispatch(tokenActions.verifyToken(token))
    }
  }, [dispatch, token])

  /**
   * Go to root if token invalid
   */
  useEffect(() => {
    let timeout
    if (error) {
      setTimeout(() => {
        navigate(ROUTE_PATH.ROOT.NAME)
      }, 3000)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [error, navigate])

  useShowMessages({ error })

  if (!token) return <Navigate to={ROUTE_PATH.ROOT.NAME} />
  else {
    return (
      <div style={{ height: '100%' }}>
        <Spin tip={MESSAGES.LOADING.VERIFYING} spinning={isVerifying}>
          {isVerified && (
            <HoverCardWrapper style={{ width: '400px', margin: '20vh auto' }}>
              <Result
                status='success'
                title='Email verified successfully'
                subTitle='Enjoy your day at our platform.'
                extra={[
                  <Button
                    type='primary'
                    key='login-btn'
                    onClick={() => navigate(ROUTE_PATH.LOGIN.NAME)}
                  >
                    back
                  </Button>
                ]}
              />
            </HoverCardWrapper>
          )}
          {error && (
            <HoverCardWrapper style={{ width: '400px', margin: '20vh auto' }}>
              <Result
                status='error'
                title='Token invalid'
                subTitle='Please try again later'
                extra={[
                  <Button
                    type='primary'
                    key='login-btn'
                    onClick={() => navigate(ROUTE_PATH.LOGIN.NAME)}
                  >
                    back
                  </Button>
                ]}
              />
            </HoverCardWrapper>
          )}
        </Spin>
      </div>
    )
  }
}

export default TokenVerificationPage
