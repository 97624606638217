import { BaseApi } from '@src/apis/base'

class FileApi extends BaseApi {
  async getFiles(query = {}) {
    const params = { ...query }

    try {
      const res = await this.client.get(this.endpoints.FILE.FILES, {
        params
      })
      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }

  /**
   * Upload file
   * @param {FormData} formData
   * @returns {Promise<*>}
   */
  async upload(formData) {
    try {
      const res = await this.client.post(this.endpoints.FILE.FILES, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }

  /**
   * Update file
   * @param {String} id
   * @param {Object} body
   * @returns {Promise<*>}
   */
  async updateFile({ id, body }) {
    try {
      const res = await this.client.put(this.endpoints.FILE.FILE(id), body)

      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }

  /**
   * Delete file
   * @param {String} id
   * @returns {Promise<*>}
   */
  async deleteFile(id) {
    try {
      const res = await this.client.delete(this.endpoints.FILE.FILE(id))

      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }
}

export { FileApi }
