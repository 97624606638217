import React from 'react'
import { HoverCardWrapper } from '@src/styles/wrapper'
import { Badge, Progress, Tag } from 'antd'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { generatePath, useNavigate } from 'react-router-dom'
import { RECORD_RESPONSE_STATUS_COLORS, ROUTE_PATH, ROUTE_QUERY_PARAMS } from '@src/constants'
import { CheckOutlined } from '@ant-design/icons'
import { QUESTION_SET_FIELDS, RECORD_FIELDS } from '@constants/db'
import { useQuery } from '@src/utils/hooks'

const PROGRESS_COLORS = {
  '0%': '#108ee9',
  '100%': '#87d068'
}

const SessionBriefCard = ({ recordId, question, response, isDisable }) => {
  const navigate = useNavigate()
  const query = useQuery()
  const answerSize = response?.[RECORD_FIELDS.RESPONSES.FIELDS.COUNT.NAME]
  const sessionId = question?.[QUESTION_SET_FIELDS.QUESTIONS.FIELDS.ID.NAME]
  const sessionName = question?.[QUESTION_SET_FIELDS.QUESTIONS.FIELDS.NAME.NAME]
  const recordStatus = response?.[RECORD_FIELDS.RESPONSES.FIELDS.STATUS.NAME]
    ? response?.[RECORD_FIELDS.RESPONSES.FIELDS.STATUS.NAME]
    : isDisable
      ? 'close'
      : 'open'
  const sessionTrials = question?.[QUESTION_SET_FIELDS.QUESTIONS.FIELDS.TRIALS.NAME]

  /**
   * Handle click card
   */
  const onClickCard = () => {
    const path = generatePath(ROUTE_PATH.SESSION.NAME, { id: sessionId })
    query.set(ROUTE_QUERY_PARAMS.RECORD.NAME, recordId)
    navigate({ pathname: path, search: query.toString() })
  }

  return (
    <Badge.Ribbon
      text={_.lowerCase(recordStatus)}
      color={RECORD_RESPONSE_STATUS_COLORS?.[recordStatus] || 'red'}
    >
      <HoverCardWrapper disabled={isDisable} style={{ cursor: 'pointer' }} onClick={onClickCard}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '80px'
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {recordStatus !== RECORD_FIELDS.STATUS.ENUMS.COMPLETED && (
              <Tag color={'orange'} style={{ width: 'fit-content' }}>
                New
              </Tag>
            )}
            <strong style={{ fontSize: '30px' }}>{sessionName}</strong>
          </div>

          <Progress
            type='dashboard'
            percent={100}
            strokeColor={PROGRESS_COLORS}
            size={60}
            format={() => {
              const finished = answerSize || 0
              const percentage = finished / sessionTrials
              if (percentage === 1) {
                return <CheckOutlined />
              } else {
                return (
                  <div style={{ padding: '5px' }}>
                    {finished} / {sessionTrials}
                  </div>
                )
              }
            }}
          />
        </div>
      </HoverCardWrapper>
    </Badge.Ribbon>
  )
}

SessionBriefCard.propTypes = {
  recordId: PropTypes.string,
  question: PropTypes.object,
  response: PropTypes.object,
  isDisable: PropTypes.bool
}

export default SessionBriefCard
