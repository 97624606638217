import { HoverCardWrapper } from '@src/styles/wrapper'
import { Button, Form, Input, Spin, Typography } from 'antd'
import { MESSAGES, REDUX_STATE, ROUTE_PATH } from '@src/constants'
import { USER_FIELDS } from '@constants/db'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '@store/actions/auth'
import { useShowMessages } from '@src/utils/hooks'

const ResetPasswordRequestPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isSubmittingEmail = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.IS_SUBMITTING_EMAIL]
  )
  const isSubmittedEmail = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.IS_SUBMITTED_EMAIL]
  )
  const error = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.ERROR]
  )
  const onFinish = (values) => {
    dispatch(authActions.resetPasswordRequest(values))
  }
  const prevIsSubmittingEmail = useRef(isSubmittingEmail)
  const [message, setMessage] = useState({ error: null })

  useShowMessages({ error })

  /**
   * Display error message
   */
  useEffect(() => {
    if (!isSubmittingEmail && prevIsSubmittingEmail.current && error) {
      setMessage({
        ...message,
        error: { message: MESSAGES.PROMPT.PROFILE_UPDATED_SUCCESSFULLY }
      })
    }
    prevIsSubmittingEmail.current = isSubmittingEmail
  }, [error, isSubmittingEmail, message])

  /**
   * Go to root if token invalid
   */
  useEffect(() => {
    let timeout
    if (isSubmittedEmail) {
      setTimeout(() => {
        navigate(ROUTE_PATH.LOGIN.NAME)
      }, 3000)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [isSubmittedEmail, navigate])

  return (
    <div style={{ height: '100%' }}>
      <Spin tip={MESSAGES.LOADING.SUBMITTING_EMAIL} spinning={isSubmittingEmail}>
        <HoverCardWrapper style={{ width: '400px', margin: '20vh auto' }}>
          {isSubmittedEmail ? (
            <>
              <Typography.Title>Reset Password</Typography.Title>
              <Typography.Text>
                {MESSAGES.PROMPT.RESET_PASSWORD_EMAIL_SUBMITTED_SUCCESSFULLY}
              </Typography.Text>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '20px'
                }}
              >
                <Button onClick={() => navigate(ROUTE_PATH.LOGIN.NAME)}>Back to Login</Button>
              </div>
            </>
          ) : (
            <>
              <Typography.Title>Reset Password</Typography.Title>
              <Form layout='vertical' onFinish={onFinish}>
                <Form.Item
                  label={MESSAGES.LABEL.EMAIL_CURRENT}
                  name={USER_FIELDS.EMAIL.NAME}
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.VALIDATION.EMAIL
                    }
                  ]}
                >
                  <Input placeholder={MESSAGES.PLACEHOLDER.EMAIL}></Input>
                </Form.Item>
                <Form.Item>
                  <Button type='primary' htmlType='submit' style={{ width: '100%' }}>
                    Submit
                  </Button>
                </Form.Item>
                <Button onClick={() => navigate(ROUTE_PATH.LOGIN.NAME)}>Back</Button>
              </Form>
            </>
          )}
        </HoverCardWrapper>
      </Spin>
    </div>
  )
}

export default ResetPasswordRequestPage
