import React, { useEffect, useRef, useState } from 'react'
import { Badge, Button, List, Popconfirm, Typography } from 'antd'
import moment from 'moment'
import { DATE_TIME_FORMAT, MESSAGES, REDUX_STATE } from '@src/constants'
import { HoverCardWrapper } from '@src/styles/wrapper'
import { CloseSquareTwoTone } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { PASSPORT_SESSION_FIELDS } from '@constants/db'
import { authActions } from '@store/actions/auth'
import { useShowMessages } from '@src/utils/hooks'

/**
 * Format sessions
 * @param sessionsArray
 * @returns {*[]}
 */
const useSessions = (sessionsArray) => {
  const [result, setResult] = useState([])

  useEffect(() => {
    const temp = [...sessionsArray]
    temp.sort((a, b) => {
      if (a.isCurrent && !b.isCurrent) {
        return -1 // a comes before b
      }
      if (!a.isCurrent && b.isCurrent) {
        return 1 // b comes before a
      }
      return 0 // a and b are unchanged
    })
    setResult(temp)
  }, [sessionsArray])

  return result
}

const RegisteredDevices = () => {
  const dispatch = useDispatch()
  const sessions = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.SESSIONS]
  )
  const formattedSessions = useSessions(sessions)
  const isForcingLogout = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.IS_FORCING_LOGOUT]
  )
  const error = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.ERROR]
  )
  const prevIsForcingLogout = useRef(isForcingLogout)

  const [successMsg, setSuccessMsg] = useState({ message: null })

  /**
   * Show success message after deletion
   */
  useEffect(() => {
    if (!error && !isForcingLogout && prevIsForcingLogout.current) {
      setSuccessMsg({ message: MESSAGES.PROMPT.LOGOUT_DEVICE_SUCCESSFULLY })
    }
    prevIsForcingLogout.current = isForcingLogout
  }, [error, isForcingLogout])

  useShowMessages({ success: successMsg })

  return (
    <List
      style={{ overflowY: 'scroll', height: 'calc(100% - 0.5em - 32px - 20px - 46px - 30px)' }}
      dataSource={formattedSessions}
      split={false}
      renderItem={(item) => {
        const platform =
          item?.[PASSPORT_SESSION_FIELDS.SESSION.NAME]?.[
            PASSPORT_SESSION_FIELDS.SESSION.FIELDS.DEVICE.NAME
          ]?.[PASSPORT_SESSION_FIELDS.SESSION.FIELDS.DEVICE.FIELDS.PLATFORM.NAME]
        const browser =
          item?.[PASSPORT_SESSION_FIELDS.SESSION.NAME]?.[
            PASSPORT_SESSION_FIELDS.SESSION.FIELDS.DEVICE.NAME
          ]?.[PASSPORT_SESSION_FIELDS.SESSION.FIELDS.DEVICE.FIELDS.BROWSER.NAME]
        const createdAt =
          item?.[PASSPORT_SESSION_FIELDS.SESSION.NAME]?.[
            PASSPORT_SESSION_FIELDS.SESSION.FIELDS.CREATED_AT.NAME
          ]
        return (
          <List.Item>
            <div style={{ width: '97%' }}>
              <Badge.Ribbon
                text={item?.isCurrent && 'Current'}
                style={{ display: !item?.isCurrent && 'none' }}
              >
                <HoverCardWrapper>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'start',
                        gap: '5px',
                        flexBasis: '95%'
                      }}
                    >
                      <Typography.Title level={4}>
                        {browser} on {platform}
                      </Typography.Title>
                      <Typography.Text strong>Activity time: </Typography.Text>
                      <Typography.Text>
                        {moment(createdAt).format(DATE_TIME_FORMAT)}
                      </Typography.Text>
                    </div>
                    {!item?.isCurrent && (
                      <Popconfirm
                        title={'Do you want to force logout'}
                        onConfirm={() =>
                          dispatch(authActions.forceLogout(item?.[PASSPORT_SESSION_FIELDS.ID.NAME]))
                        }
                      >
                        <Button
                          icon={
                            <CloseSquareTwoTone
                              twoToneColor='#eb2f96'
                              style={{ fontSize: '25px' }}
                            />
                          }
                          ghost
                          style={{ height: '50px', width: '50px', flexBasis: '5%' }}
                        />
                      </Popconfirm>
                    )}
                  </div>
                </HoverCardWrapper>
              </Badge.Ribbon>
            </div>
          </List.Item>
        )
      }}
    />
  )
}

export default RegisteredDevices
