import React from 'react'
import './App.css'
import RoutesWrapper from '@src/routes'
import { Provider } from 'react-redux'
import store from '@store/index'
import { App as AntdApp, ConfigProvider } from 'antd'
import { getCSSVariable } from '@src/utils'
import { CSS_VARIABLES } from '@src/constants'

const App = () => {
  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          components: {
            Layout: {
              headerBg: getCSSVariable(CSS_VARIABLES.SECONDARY),
              colorText: 'black',
              bodyBg: getCSSVariable(CSS_VARIABLES.BODY_BACKGROUND_COLOR),
              footerBg: 'none'
            },
            Menu: {
              colorText: 'white'
            },
            Segmented: {
              itemSelectedBg: getCSSVariable(CSS_VARIABLES.PRIMARY),
              itemSelectedColor: 'white'
            },
            Dropdown: {
              paddingBlock: '10px'
            },
            Button: {
              defaultHoverBg: 'white',
              defaultHoverColor: 'black',
              defaultHoverBorderColor: '#d9d9d9'
            },
            Result: {
              titleFontSize: 40,
              subtitleFontSize: 30
            },
            Radio: { dotSize: 10, radioSize: 20 },
            Typography: {
              titleMarginBottom: '0.5em',
              titleMarginTop: '0.9em'
            },
            Modal: { titleLineHeight: 2, titleFontSize: 18 }
          },
          token: {
            colorPrimary: getCSSVariable(CSS_VARIABLES.PRIMARY),
            colorPrimaryLight: getCSSVariable(CSS_VARIABLES.SECONDARY),
            lineHeight: 1.57,
            colorBgContainerDisabled: 'lightGrey',
            fontSizeHeading1: parseInt(getCSSVariable(CSS_VARIABLES.FONT_SIZE_HEADER_1)),
            fontSizeHeading2: parseInt(getCSSVariable(CSS_VARIABLES.FONT_SIZE_HEADER_2)),
            fontSizeHeading3: parseInt(getCSSVariable(CSS_VARIABLES.FONT_SIZE_HEADER_3)),
            fontSizeHeading4: parseInt(getCSSVariable(CSS_VARIABLES.FONT_SIZE_HEADER_4)),
            fontSizeHeading5: parseInt(getCSSVariable(CSS_VARIABLES.FONT_SIZE_HEADER_5)),
            fontSize: parseInt(getCSSVariable(CSS_VARIABLES.DEFAULT_FONT_SIZE)),
            colorBgMask: 'rgba(0, 0, 0, 0)'
          }
        }}
      >
        <AntdApp>
          <div className='App'>
            <RoutesWrapper />
          </div>
        </AntdApp>
      </ConfigProvider>
    </Provider>
  )
}

export default App
