import { BaseApi } from '@src/apis/base'

class QuestionSetApi extends BaseApi {
  async getQuestion({ id, sessionId }) {
    try {
      const res = await this.client.get(this.endpoints.QUESTION_SET.QUESTION(id), {
        params: { sessionId }
      })
      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }

  async getQuestionSet(params) {
    try {
      const res = await this.client.get(this.endpoints.QUESTION_SET.QUESTION_SETS, {
        params
      })
      return this.extractResponse(res)
    } catch (e) {
      this.errorHandler(e)
    }
  }
}

export { QuestionSetApi }
