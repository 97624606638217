import React from 'react'
import { Form, Input } from 'antd'
import { MESSAGES } from '@src/constants'
import { USER_FIELDS } from '@constants/db'

const PersonalInformationForm = () => {
  return (
    <>
      <Form.Item label={MESSAGES.LABEL.FIRST_NAME} name={USER_FIELDS.FIRST_NAME.NAME}>
        <Input />
      </Form.Item>
      <Form.Item label={MESSAGES.LABEL.LAST_NAME} name={USER_FIELDS.LAST_NAME.NAME}>
        <Input />
      </Form.Item>
      <Form.Item label={MESSAGES.LABEL.EMAIL} name={USER_FIELDS.EMAIL.NAME}>
        <Input disabled />
      </Form.Item>
    </>
  )
}

export default PersonalInformationForm
