import React from 'react'
import './index.css'
import { Typography } from 'antd'
import { isExtremeSmallDevices } from '@src/utils'
import PropTypes from 'prop-types'

const ScrollDownButton = ({ text }) => {
  return (
    <div className='arrow-down-container'>
      <Typography.Text
        strong
        style={{
          marginTop: '10px',
          color: !text ? 'lightgrey' : '#faad14',
          fontSize: isExtremeSmallDevices ? '18px' : '20px'
        }}
      >
        {text || 'Scroll Down'}
      </Typography.Text>
      <div className='arrow-down'></div>
      <div className='arrow-down'></div>
    </div>
  )
}

ScrollDownButton.propTypes = {
  text: PropTypes.string
}

export default ScrollDownButton
