import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import UserTable from '@components/admin-page/user-table'
import { LeftOutlined } from '@ant-design/icons'
import { HoverCardWrapper } from '@src/styles/wrapper'
import BasicActionButtons from '@components/basic-action-buttons'
import { Affix, Input, Spin } from 'antd'
import { getCSSVariable, isMobile } from '@src/utils'
import { CSS_VARIABLES, LIMIT_PER_PAGE, MESSAGES, REDUX_STATE } from '@src/constants'
import { useDispatch, useSelector } from 'react-redux'
import { userActions } from '@store/actions/user'
import UserList from '@components/admin-page/user-list'

const UserManagement = ({ toggleDisplay }) => {
  const dispatch = useDispatch()
  const users = useSelector(
    (state) => state?.[REDUX_STATE.USER.NAME]?.[REDUX_STATE.USER.FIELDS.USERS]
  )
  const isUpdating = useSelector(
    (state) => state?.[REDUX_STATE.USER.NAME]?.[REDUX_STATE.USER.FIELDS.IS_UPDATING]
  )

  const [inputValue, setInputValue] = useState(null)
  const timeoutRef = useRef(null)

  /**
   * Get users when user stop inputting
   */
  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      dispatch(
        userActions.getUsers({
          skip: 0,
          limit: LIMIT_PER_PAGE,
          filter: inputValue
        })
      )
    }, 500)
    return () => clearTimeout(timeoutRef.current)
  }, [dispatch, inputValue])

  /**
   * Handle search
   * @param event
   */
  const handleSearchChange = (event) => {
    setInputValue(event?.target?.value)
  }

  /**
   * Handle search
   * @param value
   */
  const handleSearch = (value) => {
    clearTimeout(timeoutRef.current)
    dispatch(
      userActions.getUsers({
        skip: 0,
        limit: LIMIT_PER_PAGE,
        filter: value
      })
    )
  }

  return (
    <div style={{ height: '100%' }}>
      <Spin spinning={isUpdating} tip={MESSAGES.LOADING.LOGGING} fullscreen />
      <HoverCardWrapper
        style={{
          margin: '0 auto'
        }}
      >
        <Affix offsetTop={parseInt(getCSSVariable(CSS_VARIABLES.HEADER_HEIGHT).replace('px', ''))}>
          <div
            style={{
              backgroundColor: 'white',
              padding: '10px 0',
              zIndex: 999,
              position: 'relative'
            }}
          >
            <BasicActionButtons
              isShowOk={false}
              cancelIcon={<LeftOutlined />}
              onCancel={toggleDisplay}
            />
            <div
              style={{
                backgroundColor: 'white',
                padding: '10px 0',
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                  fontSize: `${getCSSVariable(CSS_VARIABLES.FONT_SIZE_HEADER_2)}px`,
                  fontWeight: 600
                }}
              >
                User Management
              </div>
              <Input.Search
                style={{ width: 300 }}
                placeholder='Search by name or email'
                size={'middle'}
                onSearch={handleSearch}
                onChange={handleSearchChange}
                allowClear
                enterButton
              />
            </div>
          </div>
        </Affix>
        <div style={{ zIndex: 1, position: 'relative'}}>
          {isMobile && <UserList users={users} />}
          {!isMobile && <UserTable users={users} />}
        </div>
      </HoverCardWrapper>
    </div>
  )
}

UserManagement.propTypes = {
  toggleDisplay: PropTypes.func
}

export default UserManagement
