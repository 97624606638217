import { AuthApi } from './auth'
import { FeedbackFormApi } from '@src/apis/feedback-form'
import { FileApi } from '@src/apis/file'
import { TokenApi } from '@src/apis/token'
import { UserApi } from '@src/apis/user'
import { QuestionSetApi } from '@src/apis/question-set'
import { GeneralApi } from '@src/apis/general'
import { RecordApi } from '@src/apis/record'

const authApi = new AuthApi()
const feedbackFormApi = new FeedbackFormApi()
const fileApi = new FileApi()
const tokenApi = new TokenApi()
const userApi = new UserApi()
const questionSetApi = new QuestionSetApi()
const generalApi = new GeneralApi()
const recordApi = new RecordApi()

export {
  authApi,
  feedbackFormApi,
  fileApi,
  tokenApi,
  userApi,
  questionSetApi,
  generalApi,
  recordApi
}
