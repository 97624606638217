const AUTH_ACTION_TYPES = {
  LOGIN: 'auth/login',
  LOGOUT: 'auth/logout',
  GET_CURRENT_USER: 'auth/getCurrentUser',
  REGISTER: 'auth/register',
  UPDATE_PROFILE: 'auth/updateProfile',
  UPDATE_PASSWORD: 'auth/updatePassword',
  FORCE_LOGOUT: 'auth/forceLogout',
  RESET_PASSWORD_REQUEST: 'auth/resetPasswordRequest',
  RESET_PASSWORD: 'auth/resetPassword',
  DELETE_ACCOUNT: 'auth/deleteAccount'
}

export { AUTH_ACTION_TYPES }
