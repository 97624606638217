import React, { useState } from 'react'
import { Form, Input } from 'antd'
import { MESSAGES } from '@src/constants'
import { USER_FIELDS } from '@constants/db'
import { passwordStrength } from 'check-password-strength'
import PassWordStrengthIndicator from '@components/password-strength-indicator'

const SecuritySettingForm = () => {
  const [passStrength, setPassStrength] = useState(null)
  const [showFeedback, setShowFeedback] = useState(false)
   
  return (
    <>
      <Form.Item
        label={MESSAGES.LABEL.PASSWORD_CURRENT}
        name='passwordCurrent'
        rules={[
          {
            required: true,
            message: MESSAGES.VALIDATION.PASSWORD
          }
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label={MESSAGES.LABEL.PASSWORD_NEW}
        name={USER_FIELDS.PASSWORD.NAME}
        rules={[
          {
            required: true,
            message: MESSAGES.VALIDATION.PASSWORD
          },
          () => ({
            validator(_, value) {
              if (value) {
                const strength = passwordStrength(value).value
                setPassStrength(strength)
                if (['Medium', 'Strong'].includes(strength)) {
                  return Promise.resolve()
                } else {
                  return Promise.reject(MESSAGES.VALIDATION.PASSWORD_TOO_WEAK)
                }
              } else {
                setPassStrength(null)
                return Promise.resolve()
              }
            }
          })
        ]}
      >
        <Input.Password />
      </Form.Item>
      {passStrength && (
        <PassWordStrengthIndicator strength={passStrength} style={{ margin: '15px auto' }} />
      )}
      <Form.Item
        label={MESSAGES.LABEL.PASSWORD_CONFIRM}
        name='passwordConfirm'
        hasFeedback={showFeedback}
        rules={[
          {
            required: true,
            message: MESSAGES.VALIDATION.PASSWORD_CONFIRM
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue(USER_FIELDS.PASSWORD.NAME)) {
                setShowFeedback(true)
                if (getFieldValue(USER_FIELDS.PASSWORD.NAME) === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error(MESSAGES.VALIDATION.PASSWORD_CONFIRM_NOT_MATCH))
              }
              setShowFeedback(false)
              return Promise.resolve()
            }
          })
        ]}
      >
        <Input.Password />
      </Form.Item>
    </>
  )
}

export default SecuritySettingForm
