import { COMMON_FIELDS } from './common'

const FILE_FIELDS = {
  ...COMMON_FIELDS,
  DATA: { NAME: 'data' },
  MINE_TYPE: { NAME: 'mimeType' },
  FILE_NAME: { NAME: 'filename' },
  EXTRA: {
    NAME: 'extra',
    FIELDS: {
      WIDTH: { NAME: 'width' },
      HEIGHT: { NAME: 'height' },
      EMOTION: {
        NAME: 'emotion',
        ENUMS: {
          ANGRY: 'angry',
          NEUTRAL: 'neutral'
        }
      },
      GENDER: {
        NAME: 'gender',
        ENUMS: {
          MALE: 'male',
          FEMALE: 'female'
        }
      },
      IDENTITY: {
        NAME: 'identity'
      }
    }
  },
  SHAPES: {
    NAME: 'shapes',
    FIELDS: {
      CATEGORIES: {
        NAME: 'categories',
        ENUMS: {
          FOREHEAD: 'forehead',
          TOP_LEFT: 'topLeft',
          TOP_RIGHT: 'topRight',
          BOTTOM_LEFT: 'bottomLeft',
          BOTTOM_RIGHT: 'bottomRight'
        }
      },
      POINTS: { NAME: 'points' },
      POSITION: { NAME: 'position' },
      ID: { NAME: 'id' },
      FI: { NAME: 'fi' },
      TYPE: {
        NAME: 'type',
        ENUMS: {
          DOT: 'dot'
        }
      }
    }
  }
}

export { FILE_FIELDS }
