import { createAsyncThunk } from '@reduxjs/toolkit'
import { FILE_ACTION_TYPES } from '@store/types'
import { fileApi } from '@src/apis'
import { serializeReduxError } from '@src/utils'

const getFiles = createAsyncThunk(
  FILE_ACTION_TYPES.GET_FILES,
  async (query) => {
    return await fileApi.getFiles(query)
  },
  {
    serializeError: serializeReduxError
  }
)
const loadMoreFiles = createAsyncThunk(
  FILE_ACTION_TYPES.LOAD_MORE_FILES,
  async (query) => {
    return await fileApi.getFiles(query)
  },
  {
    serializeError: serializeReduxError
  }
)
const upload = createAsyncThunk(
  FILE_ACTION_TYPES.UPLOAD,
  async (formData) => {
    return await fileApi.upload(formData)
  },
  {
    serializeError: serializeReduxError
  }
)
const updateFile = createAsyncThunk(
  FILE_ACTION_TYPES.UPDATE_FILE,
  async ({ id, body }) => {
    return await fileApi.updateFile({ id, body })
  },
  {
    serializeError: serializeReduxError
  }
)
const deleteFile = createAsyncThunk(
  FILE_ACTION_TYPES.DELETE_FILE,
  async (id) => {
    await fileApi.deleteFile(id)
    return id
  },
  {
    serializeError: serializeReduxError
  }
)

const fileActions = {
  upload,
  deleteFile,
  updateFile,
  getFiles,
  loadMoreFiles
}
export { fileActions }
