import styled from 'styled-components'
import { Card, Layout } from 'antd'
import BackgroundImage from '@src/static/background.svg'

/**
 * Layout with background image
 */
const LayoutWithBackground = styled(Layout)`
  background-image: url(${BackgroundImage});
  width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
`

/**
 * Card with hover style
 */
const HoverCardWrapper = styled(Card)`
  height: inherit;
  width: inherit;
  transition-property: box-shadow, border-color;
  transition-duration: 0.3s, 0.3s;
  transition-timing-function: ease, ease;
  transition-delay: 0s, 0s;
  background-color: ${(props) => props.disabled && 'lightGrey'};
  pointer-events: ${(props) => props.disabled && 'none'};
  overflow: hidden;

  & > .ant-card-body {
    height: inherit;
  }

  &:hover {
    cursor: default;
    box-shadow:
      inset 1px 1px 0 0 hsla(0, 0%, 100%, 0.5),
      0 3px 8px 0 #555a64;
    border-color: lightgray;
  }
`

export { LayoutWithBackground, HoverCardWrapper }
